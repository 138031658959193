import { gridLayer } from "leaflet";

const {
  REACT_APP_RED_COLOR: redColor,
  REACT_APP_AMBER_COLOR: amberColor,
  REACT_APP_GREEN_COLOR: greenColor,
  REACT_APP_UNKNOWN_COLOR: unknownColor,
} = process.env;

const ragCalc = (
  property: number | null,
  lowValue: number,
  highValue: number
) => {
  //console.log(property);
  if (property === null) return "unknown";
  return property <= lowValue
    ? "green"
    : property <= highValue
      ? "amber"
      : "red";
};

const txRagCalc = (
  transformerUtilisation: any,
  totalkVA: any,
  transformerRatingkVA: any,
) => {
  //console.log("transformerUtilisation=", transformerUtilisation, totalkVA, transformerRatingkVA);

  let rag;

  if (transformerUtilisation === null || transformerUtilisation === undefined) {
    //console.log("transformerUtilisation === null || transformerUtilisation === undefined");
    rag = unknownColor;
  } else {

    const utilisation = (transformerUtilisation + (totalkVA / transformerRatingkVA)) * 100;
    //console.log("utilisation=", utilisation);
    switch (true) {
      case utilisation <= 80:
        rag = greenColor;
        break;
      case utilisation > 80 && utilisation <= 100:
        rag = amberColor;
        break;
      case utilisation > 100:
        rag = redColor;
        break;
      default:
        rag = unknownColor;
    }
  }
  //console.log(rag);
  return rag;
};

export interface RAGCalc {
  color: any | undefined;
  reason: string | undefined;
}

export const getNewConnectionRAG = (
  featureProps: any,
  totalkVA: number,
  isSinglePhaseConnection: boolean
): RAGCalc => {
  //console.log("getNewConnectionRAG", featureProps, totalkVA, isSinglePhaseConnection);
  const {
    TransformerMaxUtilisationPercent: transformerUtilisation,
    MaximumBranchUtilisationUpstreamPercent: cableUtilisation,
    MaxVoltDropPercent: voltDrop,
    SourceLoopOperatingImpedance: sourceLoopImpedance,
    TransformerRatingkVA,
    SystemVoltage,
    CableRatingAmps,
    NumberOfPhases: cablePhasingString,
  } = featureProps;

  //console.log("transformerUtilisation=", transformerUtilisation);

  const newTransformerUtilisation =
    transformerUtilisation === null
      ? null
      : (transformerUtilisation + (totalkVA / TransformerRatingkVA)) * 100;

  //console.log("newTransformerUtilisation=", newTransformerUtilisation);

  // get transformer rag
  const txRag = transformerUtilisation !== null ? getTransformerRagFromConductor(featureProps, totalkVA, TransformerRatingkVA) : null;

  // if tx is red, then cable is red
  if (txRag?.color === redColor) {
    //console.log("Tx is red so returning red as cable rag");
    return { color: redColor, reason: 'Transformer RAG' };
  }

  const newCableUtilisation =
    cableUtilisation === null
      ? null
      : isSinglePhaseConnection
        ? cableUtilisation + (totalkVA * (1000 / SystemVoltage)) / CableRatingAmps
        : cableUtilisation + (((totalkVA / 3) * 1000) / SystemVoltage / CableRatingAmps) * 100;

  let cablePhasing =
    cablePhasingString?.toLowerCase() === "single"
      ? 1
      : cablePhasingString?.toLowerCase() === "split"
        ? 2
        : 3;

  //console.log("rag calc", newTransformerUtilisation, newCableUtilisation, voltDrop, sourceLoopImpedance);
  const tuRAG = ragCalc(newTransformerUtilisation, 80, 100);
  const cuRAG = ragCalc(newCableUtilisation, 80, 100);
  const vdRAG = ragCalc(voltDrop, 4.0, 5.0);
  const sliRAG = ragCalc(sourceLoopImpedance, 175, 200);
  const phaseRAG = ragCalc(cablePhasing, cablePhasing, cablePhasing);
  //console.log("tuRAG", tuRAG, "cuRAG", cuRAG, "vdRAG", vdRAG, "cuRAG", sliRAG, "phaseRAG", phaseRAG);

  const rag = [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes("unknown")
    ? unknownColor
    : [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes("red")
      ? redColor
      : [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes("amber")
        ? amberColor
        : [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes("green")
          ? greenColor
          : "";

  // if tx is amber, then cable cannot be green          
  if (rag === greenColor && txRag?.color === amberColor) {
    //console.log("Tx is amber so returning amber as cable rag");
    return { color: amberColor, reason: 'Transformer RAG' };
  }

  const reason = "tu:" + tuRAG + "(" + newTransformerUtilisation + ")|" +
    "cu:" + cuRAG + "(" + newCableUtilisation + ")|" +
    "vd:" + vdRAG + "(" + voltDrop + ")|" +
    "sli:" + sliRAG + "(" + sourceLoopImpedance + ")|" +
    "ph:" + phaseRAG + "(" + cablePhasing + ")";
  //console.log(reason);
  //console.log(rag);
  return { color: rag, reason: reason };
};

export const getTransformerRagFromConductor = (
  featureProps: any,
  totalkVA: number,
  transformerRatingkVA: number
) => {
  //console.log("getTransformerRagFromConductor", featureProps);
  const {
    TransformerMaxUtilisationPercent: transformerUtilisation,
  } = featureProps;

  let color = txRagCalc(transformerUtilisation, totalkVA, transformerRatingkVA);
  return { color, reason: 'calculation' };
};

export const getTransformerRag = (
  featureProps: any,
  totalkVA: number
) => {
  //console.log("getTransformerRag", featureProps, totalkVA);
  const {
    MaxUtilisationPercent: transformerUtilisation,
    NameplateRating: transformerRatingkVA,
  } = featureProps;

  let color = txRagCalc(transformerUtilisation, totalkVA, transformerRatingkVA);
  return { color, reason: 'calculation' };
};

export const getPillarRag = (
  featureProps: any
) => {
  //console.log("getPillarRag", featureProps);
  // const {
  //   TransformerMaxUtilisationPercent: transformerUtilisation,
  // } = featureProps;
  //let color = txRagCalc(transformerUtilisation, null, null);
  return { color: unknownColor, reason: 'default' };
};
