import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import styles from "./Options.module.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectApp, setShowGroundTypes } from "../../app/appSlice";

const Options = () => {

  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const { showGroundTypes } = app;

  return (
    <div className={styles.options}>
      <h6 className={styles.header}>Options</h6>
      <ul className={styles.customList}>
        <li className={styles.listItem}>
          <span>
            <FormCheckInput checked={showGroundTypes} onChange={() => dispatch(setShowGroundTypes(!showGroundTypes))} />
          </span>
          <span>
            <b className={styles.listItemText}>Show ground types</b>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Options;
