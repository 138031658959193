import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

interface Inputs {
  quotationRef: string;
  connectionMethod: string;
  singlePhaseConnections: number;
  threePhaseConnections: number;
  connections: number;
  multiSwitchConnections: string[];
  searchType: "Postcode" | "Grid Ref";
  searchValue: string;
  isSiteExcavationCostIncluded: boolean;
  isNewStudy: boolean;
  existingStudy?: any;
  totalkVA: number;
  userRole?: string;
  buildingOverride: boolean;
}

interface Dialog {
  props?: any[];
  type?: string;
  className?: string;
  affirmLabel?: string;
  dismissLabel?: string;
  messages?: { description: string }[];
  info?: string[];
}

// Define a type for the slice state
export interface AppState {
  inputs: Inputs;
  dialog: Dialog;
  mouseOverControl: boolean;
  screenshotMode: boolean;
  submit: boolean;
  showGroundTypes: boolean;
}

// Define the initial state using that type
export const initialState: AppState = {
  inputs: {
    quotationRef: "",
    connectionMethod: "manual",
    singlePhaseConnections: 0,
    threePhaseConnections: 0,
    connections: 0,
    multiSwitchConnections: [],
    searchType: "Postcode",
    searchValue: "",
    isSiteExcavationCostIncluded: false,
    isNewStudy: true,
    existingStudy: undefined,
    totalkVA: 0,
    userRole: undefined,
    buildingOverride: false
    },
  dialog: {},
  mouseOverControl: true,
  screenshotMode: false,
  submit: false,
  showGroundTypes: false
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSubmit: (state, action: PayloadAction<boolean>) => {
      state.submit = action.payload;
    },
    setScreenshotMode: (state, action: PayloadAction<boolean>) => {
      state.screenshotMode = action.payload;
    },
    updateDialog: (state, action) => {
      state.dialog = action.payload;
    },
    setInputs: (state, action: PayloadAction<Inputs>) => {
      state.inputs = action.payload;
    },
    setMouseOverControl: (state, action: PayloadAction<boolean>) => {
      state.mouseOverControl = action.payload;
    },
    setShowGroundTypes: (state, action: PayloadAction<boolean>) => {
      state.showGroundTypes = action.payload;
    },
    setBuildingOverride: (state, action: PayloadAction<boolean>) => {
      state.inputs.buildingOverride = action.payload;
    },
  },
});

export const {
  setSubmit,
  setScreenshotMode,
  updateDialog,
  setInputs,
  setMouseOverControl,
  setShowGroundTypes,
  setBuildingOverride
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;
