import { Map } from "leaflet";
import { useState, useEffect } from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import Controls from "../../containers/Controls";
import Sidebar from "../sidebar/Sidebar";
import NetworkCreator from "../../features/networkCreator/NetworkCreator";
import Steps from "../../features/steps/Steps";

import { useAppDispatch } from "../../app/hooks";
import { setInputs } from "../../app/appSlice";
import {
  replaceNetwork,
  clearNetwork,
  NetworkCreatorState,
} from "../../features/networkCreator/networkCreatorSlice";

import {
  postcodeToSetView,
  gridRefToSetView,
  polygonCentroidToSetView,
  latLngToSetView,
} from "../../utils/convertToSetView";

import Overlay from "../overlay/Overlay";

import RequestBuilder from "../requestBuilder/RequestBuilder";
import { clearExistingNetwork } from "../../features/existingNetwork/existingNetworkSlice";
import { AxiosError } from "axios";

const {
  REACT_APP_API_URL: apiUrl,
} = process.env;

const getLatLng = (map: Map, searchType: string, searchValue: string) => {
  return new Promise<void>((resolve, reject) => {
    if (searchType === "Postcode") {
      postcodeToSetView(map, searchValue)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }
    if (searchType === "Grid Ref") {
      gridRefToSetView(map, searchValue);
      resolve();
    }
    if (searchType === "LatLng") {
      latLngToSetView(map, searchValue);
      resolve();
    }
  });
};

interface ILeafletMap {
  marginX: string;
  marginY: string;
}

function LeafletMap({ marginX, marginY }: ILeafletMap) {
  const [map, setMap] = useState<Map | null>(null);
  const [showOverlay, setShowOverlay] = useState<boolean>(true);
  const [showLayerOverlay, setShowLayerOverlay] = useState<boolean>(false);
  const [showNetworkOverlay, setShowNetworkOverlay] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [clickDetected, setClickDetected] = useState(false);

  useEffect(() => {
    window.parent.postMessage(
      {
        everythingReady: true,
      },
      "*"
    );
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleObj, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    document.addEventListener("busyLayers", busyLayers, false);
    document.addEventListener("notbusyLayers", notBusyLayers, false);
    document.addEventListener("busyNetwork", busyNetwork, false);
    document.addEventListener("notbusyNetwork", notBusyNetwork, false);
  }, []);

  const busyLayers = (e: any) => {
    //console.log("busyLayers");
    e.preventDefault();
    setShowLayerOverlay(true);
  }
  const notBusyLayers = (e: any) => {
    //console.log("notbusyLayers");
    e.preventDefault();
    setShowLayerOverlay(false);
  }

  const busyNetwork = (e: any) => {
    //console.log("busyNetwork");
    e.preventDefault();
    setShowNetworkOverlay(true);
  }
  const notBusyNetwork = (e: any) => {
    //console.log("notbusyNetwork");
    e.preventDefault();
    setShowNetworkOverlay(false);
  }

  const handleObj = async (e: any) => {
    if (!clickDetected) {
      setClickDetected(true);

      dispatch(clearNetwork());
      dispatch(clearExistingNetwork());

      if (map && e.data.searchValue) {

        //console.info("inputs", e.data);

        // set inputs here so that the map.SetView call (which triggers convertFeatures calls), is called with the correct inputs
        dispatch(setInputs(e.data));

        let errorOccurred = false;
        try {
          await getLatLng(map, e.data.searchType, e.data.searchValue);
        }
        catch (error) {
          errorOccurred = true;
          const axiosError = (error as AxiosError);
          if (axiosError) {
            if (e.data.searchType === "Postcode" &&
              axiosError.response?.status === 404 &&
              axiosError.response?.config.url?.toLowerCase().startsWith("https://api.postcodes.io/postcodes/")) {
              setShowOverlay(true);
              window.parent.postMessage(
                {
                  invalidPostCode: e.data.searchValue,
                },
                "*"
              );
            }
          }
        }

        if (!errorOccurred) {
          setShowOverlay(false);
        }
      }     
      if (map && e.data.existingStudy) {
        //console.info("inputs", e.data);
        const { existingStudy } = e.data;

        // set inputs here so that the map.SetView call (which triggers convertFeatures calls), is called with the correct inputs
        dispatch(setInputs(existingStudy.inputs));

        polygonCentroidToSetView(map, existingStudy);
        setShowOverlay(false);

        existingStudy.history.forEach((r: any) => {
          dispatch(replaceNetwork(r));
        });
        const r: NetworkCreatorState = {
          network: existingStudy.network,
          activeTool: undefined,
          vertices: [],
          currentSegments: [],
          intersectingSegments: existingStudy.intersectingSegments,
          siteIntersectingPolygons: existingStudy.siteIntersectingPolygons,
        };
        dispatch(replaceNetwork(r));
      }

      setClickDetected(false);
    }
  };

  const onTileLoading = (e: any) => {
    //console.log("Tile loading", e);
  }

  const onTileLoad = (e: any) => {
    //console.log("Tile loaded", e);
  }

  return (
    <>
      <MapContainer
        id="map"
        style={{
          width: `calc(100% - ${marginX}rem)`,
          height: `calc(100% - ${marginY}rem)`,
        }}
        center={[54.571544, -5.946985]}
        zoom={18}
        minZoom={15}
        maxZoom={20}
        scrollWheelZoom={true}
        zoomControl={false}
        doubleClickZoom={false}
        tap={false}
        whenCreated={setMap}
      >
        <TileLayer
          id="tilelayer"
          attribution='&copy; <a href="https://www.nidirect.gov.uk/articles/osni-fusion" target="new">OSNI Fusion</a>'
          url={apiUrl + "/tile/{z}/{x}/{y}.png"}
          maxZoom={20}
          maxNativeZoom={19}
          eventHandlers={{
            load: onTileLoad,
            loading: onTileLoading
          }}        />
        <Controls />
        <NetworkCreator />
        <RequestBuilder />
      </MapContainer>
      <Sidebar>
        <Steps />
      </Sidebar>
      <Overlay show={showOverlay}></Overlay>

      <div style={{ fontSize: "0.7rem", zIndex: 10000, position: "absolute", bottom: "0", left: `calc(30%)` }}>
        {showLayerOverlay && (
          <>
            <span>Loading layers... </span>
          </>
        )}
        {showNetworkOverlay && (
          <>
            <span>Loading network... </span>
          </>
        )}
      </div>
    </>
  );
}

export default LeafletMap;
