import L, { LatLngTuple } from "leaflet";
import { deviceType } from "detect-it";
import { LatLng, LeafletMouseEvent } from "leaflet";
import pointInPolygon from "point-in-polygon";
import { useState } from "react";
import {
  GeoJSON,
  Marker,
  Pane,
  Polyline,
  useMap,
  useMapEvents,
  MapContainer
} from "react-leaflet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { featureCollection, multiLineString } from "@turf/helpers";
import {
  Feature,
  GeoJsonProperties,
  LineString,
  MultiPolygon,
  Polygon,
  Position,
} from "geojson";
import { updateDialog, setBuildingOverride } from "../../app/appSlice";
import DialogModal from "../../components/dialogModal/DialogModal";
import {
  FeatureData,
  LatLngObj,
  NetworkAsset,
  PointOfConnectionType,
} from "../../models/models";
import { getLineString, getPolygon, getGroundTypes } from "../../services/osniFusionAPI";
import { checkArrayList } from "../../utils/checkArrayList";
import { filterByProperty } from "../../utils/filterByProperty";
import { iconMarker } from "../../utils/iconMarker";
import "../../utils/iconMarker.css";
import { updateConnectedAssets } from "../../utils/networkConnector";
import {
  asset,
  getPolygonFeatureData,
  getLineStringFeatureData,
  latLngArray,
  matchNetworkAsset,
  isPolygon,
  isLineString
} from "../../utils/networkUtils";
import {
  refineSnapping,
  _calcClosestAsset,
  _calcLayerDistances,
} from "../../utils/snapping.js";
import { selectExistingNetwork, updateGroundTypes } from "../existingNetwork/existingNetworkSlice";
import Hints from "./Hints";
import {
  addToNetwork,
  addVertex,
  completeAsset,
  completeAsset2,
  completeSegment,
  updateAsset,
  selectNetworkCreator
} from "./networkCreatorSlice";
import kinks from "@turf/kinks";

import { selectApp } from "../../app/appSlice";
import { ActionCreators } from "redux-undo";

const snappableVertex = (
  activeTool: string | undefined,
  vertices: LatLngObj[]
) => {
  return activeTool === "cable" ? vertices[vertices.length - 1] : vertices[0];
};

const ragConnectivity = process.env.REACT_APP_RAG_CONNECTIVITY?.split(",");

const allowBuildingOverride = (process.env.REACT_APP_ALLOW_BUILDING_OVERRIDE === "true");

const DrawingTool = () => {
  const map = useMap();
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const { conductingSections, overheadPoles, topographies } = existingNetwork;
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { network, vertices, firstConnectedAsset, activeTool } =
    networkCreator;
  const dispatch = useAppDispatch();
  const [currentLatLng, setCurrentLatLng] = useState<LatLngObj | undefined>(
    undefined
  );
  const [isSnapped, setIsSnapped] = useState(false);
  const [withinBounds, setWithinBounds] = useState<boolean>(true);
  const [clickDetected, setClickDetected] = useState(false);

  const [meterLatLng, setMeterLatLng] = useState<LatLngObj | undefined>(
    undefined
  );

  const maxCableRouteLength = 200;

  const [currentSnappedAsset, setCurrentSnappedAsset] = useState<
    NetworkAsset | undefined
  >(undefined);

  const [invalidIntersections, setInvalidIntersections] = useState<
    Position[][]
  >([]);

  const [buildingOverrideArray, setBuildingOverrideArray] = useState<GeoJsonProperties[]>([]);

  const [invalidPoints, setInvalidPoints] = useState<Position[][]>([]);

  const [currentMapBounds, setCurrentMapBounds] = useState<L.LatLngBounds | undefined>(undefined);

  const app = useAppSelector(selectApp);
  const { showGroundTypes } = app;

  const siteSnapList = () => {
    // Get either first or last vertex depending if the activeTool is a cable or site
    const vertex = snappableVertex(activeTool, vertices);

    // Create network asset from vertex
    const hintMarker: NetworkAsset = asset(undefined, "marker", vertex);

    // Copy network
    // Temporarily limit snapping
    // let snappableAssets = [...network];
    let snappableAssets: NetworkAsset[] = [];

    if (vertices.length) {
      // Temporarily limit snapping
      // snappableAssets = [hintMarker, ...network];
      snappableAssets = [hintMarker];
    }

    return snappableAssets;
  };

  const cableSnapList = () => {
    // Get either first or last vertex depending if the activeTool is a cable or site
    const vertex = snappableVertex(activeTool, vertices);

    // Create network asset from vertex
    const hintMarker: NetworkAsset = asset(undefined, "marker", vertex);

    // Filter network by markers
    let filteredNetwork =
      firstConnectedAsset?.type !== "marker"
        ? network.filter((n) => n.type === "marker")
        : [];

    const undergroundConductingSections = conductingSections.filter(
      (c) => (c?.properties as any).Class === "underground-cable"
    );

    const filteredConductingSections =
      firstConnectedAsset?.type !== "existingcable"
        ? filterByProperty(
          ragConnectivity || [],
          undergroundConductingSections,
          "color"
        )
        : [];
    const filteredOverheadPoles =
      firstConnectedAsset?.type !== "existingcable"
        ? filterByProperty(ragConnectivity || [], overheadPoles, "color")
        : [];

    // Copy network
    let snappableAssets = [
      ...filteredNetwork,
      ...filteredConductingSections,
      ...filteredOverheadPoles,
    ];

    if (vertices.length) {
      snappableAssets = [
        hintMarker,
        ...filteredNetwork,
        ...filteredConductingSections,
        ...filteredOverheadPoles,
      ];
    }

    return snappableAssets;
  };

  const validCableConnections = [
    ["cable", "cable"],
    ["cable", "new-domestic"],
    ["cable", "new-non-domestic"],
    ["cable", "new-mixed"],
    ["cable", "existing-domestic"],
    ["cable", "existing-non-domestic"],
    ["cable", "existing-mixed"],
    ["cable", "existingcable"],
    ["cable", "marker"],
    ["cable", "cabinet"],
    ["new-domestic", "existingcable"],
    ["new-non-domestic", "existingcable"],
    ["new-mixed", "existingcable"],
    ["existing-domestic", "existingcable"],
    ["existing-non-domestic", "existingcable"],
    ["existing-mixed", "existingcable"],
    ["existingcable", "marker"],
    ["existingcable", "cabinet"],
  ];

  const validateCable = (
    firstConnectedAsset: NetworkAsset | undefined,
    secondConnectedAsset: NetworkAsset | undefined
  ) => {
    if (!firstConnectedAsset || !secondConnectedAsset) return false;
    const assetTypes = [
      firstConnectedAsset.type,
      secondConnectedAsset.type,
    ].sort();
    return checkArrayList(assetTypes, validCableConnections);
  };

  const isNetworkConnected = (connectedAssets?: NetworkAsset[]) => {
    if (!connectedAssets) return false;
    const assetIds = connectedAssets.map((p) => p.id);
    const filteredNetwork = [...network].filter(
      (f) => !assetIds.includes(f.id)
    );
    const updatedNetwork = [...filteredNetwork, ...connectedAssets];
    return updatedNetwork
      .filter((a) => a.type !== "site")
      .find((a) => !a.isConnected);
  };

  const getInvalidFeatures = (invalidTypes: any[]) => {
    var invalidFeatures: string[] = [];
    invalidTypes.map((x) => {
      let result = "";
      if (x?.CategoryCode != null) {
        result += x?.CategoryCode;
        if (x?.TypeCode != null) {
          result += ", ";
        }
      }
      if (x?.TypeCode != null) result += x?.TypeCode;

      if (result != "") {
        if (invalidFeatures.indexOf(result) === -1) {
          invalidFeatures.push(result);
        }
      }
    })
      .filter(x => x !== undefined);
    return invalidFeatures;
  };

  const applyGroundTypesIfRequired = async (_activeTool: string | undefined = undefined) => {

    //console.log("applyGroundTypesIfRequired");

    if (app.inputs.buildingOverride) {
      //console.log("buildingOverride set");
      return;
    }

    // only if we have some ground types
    if (topographies && topographies.length > 0) {

      // let siteAsset = dispatch(
      //   getAsset({
      //     site.id
      //   })
      // );

      //console.log("network=", network);

      const site = network.find((f) => f.type === "site");
      //console.log("Site.Id", site?.id, site?.type);

      const allVertices = site?.polyGeometry as LatLngObj[];
      const featureResult = await runFeatures(allVertices, "polygon");
      //console.log(featureResult);

      if (featureResult?.invalid) {
        if (allowBuildingOverride && featureResult.invalidTypes.every((f) => f?.TOPOGRAPHYTYPE === "building")) {
          dispatch(
            updateDialog({
              type: "warning",
              className: "warning",
              affirmLabel: "Yes",
              dismissLabel: "No",
              messages: [
                {
                  description:
                    "Your site has crossed/contains a building. Do you want to allow this?"
                }
              ],
              //info: getInvalidFeatures(featureResult?.invalidTypes),
              props: [activeTool, allVertices, featureResult]
            })
          );
        } else {
          dispatch(
            updateDialog({
              type: "warning",
              className: "warning",
              dismissLabel: "OK",
              messages: [
                {
                  description:
                    "Please note, your site has crossed/contains an invalid ground type. Please check your route."
                }
              ],
              info: getInvalidFeatures(featureResult?.invalidTypes),
              props: [activeTool, allVertices, featureResult]
            })
          );
        }
        return;
      }

      // update this sites features
      dispatch(
        updateAsset({
          siteId: site?.id !== undefined ? site?.id : "",
          features: featureResult?.features ?? [],
        })
      );

      //console.log("network now=", network);
    }
    else {
      //console.log("No ground types to check:", topographies, topographies.length);
    }
  };

  const completeSite = async (_activeTool: string | undefined = undefined) => {
    const allVertices = [...vertices];
    //console.log(allVertices);
    const featureResult = await runFeatures(allVertices, "polygon");
    if (featureResult?.invalid) {
      if (allowBuildingOverride && featureResult.invalidTypes.every((f) => f?.TOPOGRAPHYTYPE === "building")) {
        dispatch(
          updateDialog({
            type: "warning",
            className: "warning",
            affirmLabel: "Yes",
            dismissLabel: "No",
            messages: [
              {
                description:
                  "Your site has crossed/contains a building. Do you want to allow this?"
              }
            ],
            //info: getInvalidFeatures(featureResult?.invalidTypes),
            props: [_activeTool, allVertices, featureResult],
          })
        );
      } else {
        dispatch(
          updateDialog({
            type: "warning",
            className: "warning",
            dismissLabel: "OK",
            messages: [
              {
                description:
                  "Please note, your site has crossed/contains an invalid ground type. Please check your route."
              }
            ],
            info: getInvalidFeatures(featureResult?.invalidTypes)
          })
        );
      }
      return;
    }

    let assets: NetworkAsset[] = [];
    const connectedAssets = undefined;

    assets.push(
      asset(
        undefined,
        activeTool as string,
        undefined,
        allVertices,
        undefined,
        undefined,
        firstConnectedAsset?.id
      )
    );

    dispatch(
      completeAsset({
        connectedAssets,
        assets,
        _activeTool,
        features: featureResult?.features ?? [],
      })
    );
  };

  const getJoint = (secondConnectedAsset: NetworkAsset | undefined) => {
    return firstConnectedAsset?.type === "existingpole" ||
      firstConnectedAsset?.type === "existingcable"
      ? vertices[0]
      : secondConnectedAsset?.type === "existingpole" ||
        secondConnectedAsset?.type === "existingcable"
        ? currentLatLng
        : undefined;
  };

  const getPointOfConnectionType = (arr: any, arr2: string[]) => {
    return arr.find((a: string) => arr2.includes(a));
  };

  const completeCable = (features?: FeatureData[]) => {
    const allVertices = [...vertices, currentLatLng as LatLngObj];

    // Init empty array
    let assets: NetworkAsset[] = [];
    // Add either site or cable to array with vertices including current latlng

    let isConnected = undefined;
    let pointOfConnectionType: PointOfConnectionType | undefined;
    let secondConnectedAsset = undefined;
    if (isSnapped && currentSnappedAsset) {
      secondConnectedAsset = currentSnappedAsset;
      isConnected = validateCable(firstConnectedAsset, secondConnectedAsset);
      pointOfConnectionType = getPointOfConnectionType(
        [firstConnectedAsset?.type, secondConnectedAsset.type],
        ["existingcable", "existingpole"]
      );
    }

    assets.push(
      asset(
        undefined,
        activeTool as string,
        undefined,
        allVertices,
        undefined,
        undefined,
        firstConnectedAsset?.id,
        secondConnectedAsset?.id,
        isConnected,
        undefined
      )
    );

    const markerGeometry = getJoint(secondConnectedAsset);

    if (markerGeometry) {
      assets.push(
        asset(
          undefined,
          "marker",
          markerGeometry,
          undefined,
          "joint",
          undefined,
          undefined,
          undefined,
          true,
          pointOfConnectionType
        )
      );
    }

    const connectedAssets = updateConnectedAssets(
      assets,
      network,
      conductingSections,
      overheadPoles
    );
    const _activeTool = isNetworkConnected(connectedAssets) ? "cable" : "";

    dispatch(
      completeAsset2({
        connectedAssets,
        assets,
        _activeTool,
        features,
      })
    );
  };

  const runFeatures = async (
    line: LatLngObj[],
    geometry: "lineString" | "polygon"
  ) => {
    //console.log("runFeatures", line, geometry);

    let invalid = false;
    const _features: FeatureData[] = [];
    const coords: Position[] = line.map((m) => [m.lat, m.lng]);
    //console.log("coords", coords);

    const intersectingFeatures = getLineString(coords, topographies);
    intersectingFeatures.features.forEach((feature) => {
      if (isPolygon(feature as Feature<Polygon>)) {
        if (!buildingOverrideArray.find((f) => f?.Id === feature.properties?.Id)) {
          //console.log("polygon");
          //console.log(feature);
          _features.push(
            getPolygonFeatureData(
              feature as Feature<Polygon, GeoJsonProperties>,
              coords
            )
          );
        }
      }
      if (isLineString(feature as Feature<LineString>)) {
        //console.log("linestring");
        //console.log(feature);
        _features.push(
          getLineStringFeatureData(
            feature as Feature<LineString, GeoJsonProperties>,
            coords
          )
        );
      }
    });

    // if we have a site then check containing features
    if (activeTool === "site") {
      const polyCoords = [...coords, coords[0]];
      const coordsArray: Position[][] = [polyCoords];
      //console.log("coords", coords);
      const containedFeatures = getPolygon(coordsArray, topographies.filter((x) => x.geometry.type == "MultiPolygon") as Feature<MultiPolygon, GeoJsonProperties>[]);
      containedFeatures.features.forEach((feature) => {
        if (isPolygon(feature as Feature<Polygon>)) {
          //console.log("polygon");
          //console.log(feature);
          _features.push(
            getPolygonFeatureData(
              feature as Feature<Polygon, GeoJsonProperties>,
              coords
            )
          );
        }
      });
      if (!intersectingFeatures && !containedFeatures) return;
    } else {
      // otherwise active tool is a cable
      if (!intersectingFeatures) return;
    }


    //console.log("intersectingFeatures", intersectingFeatures);

    var invalidLineStrings = null;
    var invalidMarkers = null;

    let invalidTypes: GeoJsonProperties[] = [];

    // if site
    if (geometry === "polygon") {

      var inv = _features
        .filter(
          (f) =>
            f.display === "lineString" &&
            (
              f.properties?.TOPOGRAPHYTYPE === "transport_casing"
              || f.properties?.TOPOGRAPHYTYPE === "water_course_polygon"
              || f.properties?.TOPOGRAPHYTYPE === "building"
            )
        );
      inv.forEach((x) => invalidTypes.push(x.properties));
      invalidLineStrings = inv.map((m) => m.coords);

      // inv = _features
      //   .filter(
      //     (f) =>
      //       f.display === "marker" &&
      //       (
      //         f.properties?.TOPOGRAPHYTYPE === "road_link"
      //       )
      //   );
      // inv.forEach((x) => invalidTypes.push(x.properties));
      // invalidMarkers = inv.map((m) => m.coords);
    }
    //console.log("invalidMarkers", invalidMarkers);

    // if (invalidMarkers?.length) {
    //   setInvalidPoints(invalidMarkers);
    //   invalid = true;
    // }

    // cable
    if (geometry === "lineString") {
      var inv = _features
        .filter(
          (f) =>
            f.display === "lineString" &&
            (
              f.properties?.TOPOGRAPHYTYPE === "building"
              || f.properties?.TOPOGRAPHYTYPE === "water_course_polygon"
            )
        );
      inv.forEach((x) => invalidTypes.push(x.properties));
      invalidLineStrings = inv.map((m) => m.coords);
    }
    //console.log("invalidLineStrings", invalidLineStrings);

    if (invalidLineStrings?.length) {
      setInvalidIntersections(invalidLineStrings);
      invalid = true;
    }

    return { features: _features, invalid, invalidTypes };
  };

  const handleSnapping = (e: LeafletMouseEvent) => {
    const snappableAssets =
      activeTool === "cable" ? cableSnapList() : siteSnapList();
    const closestAsset = _calcClosestAsset(map, e.latlng, snappableAssets);
    const refinedSnapping = refineSnapping(map, e, closestAsset);
    return { closestAsset, refinedSnapping };
  };

  const handleMeter = async (e: LeafletMouseEvent) => {
    if (!clickDetected) {
      try {
        setClickDetected(true);

        let _currentLatLng = currentLatLng;
        const meter = network.find((f) => f.type === "site");
        if (deviceType !== "mouseOnly") {
          const snap = _calcLayerDistances(map, e.latlng, meter).latlng;
          setCurrentLatLng(snap);
          setWithinBounds(isWithinBounds(e.latlng));
        }

        const assets = [];
        assets.push(
          asset(
            undefined,
            "marker",
            _currentLatLng as LatLngObj,
            undefined,
            activeTool,
            undefined,
            undefined,
            undefined,
            false,
            undefined,
            true
          )
        );

        // used for total length calcs
        setMeterLatLng(_currentLatLng);

        dispatch(
          addToNetwork({
            assets,
            _activeTool: undefined,
          })
        );
      }
      finally {
        setClickDetected(false);
      }
    }
  };

  const handleCable = async (e: LeafletMouseEvent) => {
    if (!clickDetected) {
      try {
        setClickDetected(true);

        let snap = currentLatLng;
        let _withinBounds = withinBounds;
        if (deviceType !== "mouseOnly") {
          snap = handleSnapping(e).refinedSnapping;
        }
        if (!_withinBounds) return;

        if (vertices.length) {
          const line = [vertices[vertices.length - 1], snap as LatLngObj];
          if (line.length === 2) {
            if (line[0].lat === line[1].lat && line[0].lng === line[1].lng) {
              dispatch(
                updateDialog({
                  type: "warning",
                  className: "warning",
                  dismissLabel: "OK",
                  messages: [
                    {
                      description:
                        "Please check your route - your cable route cannot connect back to the same point",
                    },
                  ],
                })
              );
              dispatch(ActionCreators.undo());
              return;
            }
          }

          await runFeatures(line, "lineString").then((result) => {
            if (!result) return;
            if (result.invalid) {
              dispatch(
                updateDialog({
                  props: [snap, result.features],
                  type: "warning",
                  className: "warning",
                  dismissLabel: "OK",
                  messages: [
                    {
                      description:
                        "Please note, your cable route has crossed an invalid ground type. Please check your route.",
                    },
                  ],
                  info: getInvalidFeatures(result?.invalidTypes)
                })
              );
            } else {
              if (cablesOverMaxCableRouteLength(line)) {
                dispatch(
                  updateDialog({
                    type: "warning",
                    className: "warning",
                    dismissLabel: "OK",
                    messages: [
                      {
                        description:
                          "Please check your route - your cable route cannot be more than " +
                          maxCableRouteLength +
                          "m",
                      },
                    ],
                  })
                );
                return;
              }

              if (firstConnectedAsset && isSnapped && currentSnappedAsset) {
                if (
                  firstConnectedAsset.id !== currentSnappedAsset.id &&
                  firstConnectedAsset.type !== currentSnappedAsset.type
                ) {
                  finaliseCable(snap, result.features);
                }

                return;
              }
              finaliseCable(snap, result.features);
            }
          });
        } else {
          finaliseCable(snap);
        }
      }
      finally {
        setClickDetected(false);
      }
    }
  };

  const finaliseCable = (snap?: LatLngObj, features?: FeatureData[]) => {
    let _currentSnappedAsset: NetworkAsset | undefined;
    if (vertices.length < 1) {
      if (isSnapped && currentSnappedAsset) {
        _currentSnappedAsset = currentSnappedAsset;
      }
    }

    if (vertices.length && isSnapped) {
      completeCable(features);
      return;
    }

    if (!vertices.length && !isSnapped) {
      return;
    }

    dispatch(
      completeSegment({
        snap,
        features,
        firstConnectedAsset: _currentSnappedAsset,
      })
    );
    setInvalidIntersections([]);
    setInvalidPoints([]);
  };

  const handleSite = async (e: LeafletMouseEvent) => {
    try {
      setClickDetected(true);
      if (!clickDetected) {
        let snap = currentLatLng;
        if (deviceType !== "mouseOnly") {
          snap = handleSnapping(e).refinedSnapping;
        }

        if (vertices.length === 0) {
          // no layers so get them now - should wait for them to be retrieved by async on site tool click
          getLayers();
        }
        // Get first vertex
        const marker = vertices[0];

        // If there is a first or last vertex and it is clicked, we want to complete the shape
        if (marker && latLngArray(snap as LatLngObj, marker)) {
          if (vertices.length === 1) return;
          await completeSite();

          // If vertex is not clicked
        } else {
          if (siteSelfIntersects(snap as LatLngObj)) {
            dispatch(
              updateDialog({
                props: [],
                type: "warning",
                className: "warning",
                dismissLabel: "Close",
                messages: [
                  {
                    description:
                      "The site boundary that you have drawn is incorrect.",
                  },
                ],
              })
            );
          } else {
            // add vertex
            dispatch(addVertex(snap as LatLngObj));
          }
        }
      }
    }
    finally {
      setClickDetected(false);
    }
  };

  const siteSelfIntersects = (latLng: LatLngObj) => {
    const snapPoint: Position = [latLng.lng, latLng.lat];
    const sitePoints: Position[] = vertices.map((m) => [m.lng, m.lat]);
    const sitePointsArray: Position[][] = [[...sitePoints, snapPoint]];

    const points = multiLineString(sitePointsArray);
    const intersections = kinks(points);

    return intersections.features.length > 0;
  };

  const isWithinBounds = (e: LatLng) => {
    const site = network.find((n) => n.type === "site");
    let _site: any = [];
    if (site && site.polyGeometry instanceof Array) {
      _site = (site.polyGeometry as LatLngObj[]).map((geo) => [
        geo.lat,
        geo.lng,
      ]);
    }
    return pointInPolygon([e.lat, e.lng], _site);
  };

  const compareLatLng = (a: LatLngObj, b: LatLng) => {
    return a.lat === b.lat && a.lng === b.lng;
  };

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getLayers = async () => {

    const newMapBounds = map.getBounds();

    if (currentMapBounds !== undefined && currentMapBounds.contains(newMapBounds)) {
      //console.log("already got layers!");
      return;
    }

    //console.log("get layers..");
    setCurrentMapBounds(newMapBounds);

    document.dispatchEvent(new Event("busyLayers"));
    await getGroundTypes(map.getBounds()).then(async (featureCollection) => {
      if (!featureCollection) return;
      //await timeout(5000);
      dispatch(updateGroundTypes(featureCollection.features));
      //console.log("dispatch(updateGroundTypes(featureCollection.features))");
    })
      .finally(() => {
        document.dispatchEvent(new Event("notbusyLayers"));
        applyGroundTypesIfRequired();
      })
  };

  useMapEvents({
    moveend() {
      //getLayers();
    },
    mousemove(e) {
      if (activeTool === "meter") {
        const site = network.find((f) => f.type === "site");
        const snap = _calcLayerDistances(map, e.latlng, site).latlng;
        setCurrentLatLng(snap);
      } else {
        const snap = handleSnapping(e);
        setCurrentSnappedAsset(snap.closestAsset.asset);
        setCurrentLatLng(snap.refinedSnapping);
        setIsSnapped(!compareLatLng(snap.refinedSnapping, e.latlng));
        // activeTool === "cable" && setWithinBounds(isSnapped);
      }
    },
    click(e) {
      switch (activeTool) {
        case "site":
          return handleSite(e);
        case "meter":
          applyGroundTypesIfRequired(); // catch up on slow getLayers - maybe not needed now as we stop meter placement until layers are retrieved?
          return handleMeter(e);
        case "cable":
          return handleCable(e);
        default: {
          setClickDetected(false);
          return true;
        }
      }
    },
  });

  const getIconSize = (network: NetworkAsset[], latlng: LatLngObj) => {
    const marker = matchNetworkAsset(network, latlng);
    return marker && marker.name ? 1 : 1;
  };

  const styleIcon = (index: number, latlng: LatLngObj) => {
    const iconSize = getIconSize(network, latlng);
    const getIndex = activeTool === "cable" ? vertices.length - 1 : 0;
    const iconGlow = getIndex === index ? "iconGlow" : "";
    return iconMarker(iconSize, `iconBorder ${iconGlow}`);
  };

  const handleDialogDismissAction = (props?: any[]) => {
    dispatch(updateDialog({}));

    if (props && props.length == 3) {
      const _activeTool: string | undefined = props[0];
      //console.log("_activeTool is ", _activeTool);
      // remove meter and site completion if meter is the active tool
      if (_activeTool == "meter") {
        //undo the meter placement
        dispatch(ActionCreators.undo());
        // undo the site completion
        dispatch(ActionCreators.undo());
        dispatch(ActionCreators.undo());
      } else if (_activeTool === "site") {
        // undo the site completion
        dispatch(ActionCreators.undo());
        dispatch(ActionCreators.undo());
      }
    }
    setInvalidIntersections([]);
    setInvalidPoints([]);
  };

  const handleDialogAffirmAction = (props?: any[]) => {
    if (!props) return;
    dispatch(updateDialog({}));

    // Allow bulding override
    if (props.length == 3) {
      const _activeTool: string | undefined = props[0];
      const allVertices: LatLngObj[] = props[1];
      const featureResult = props[2];

      let assets: NetworkAsset[] = [];
      const connectedAssets = undefined;

      assets.push(
        asset(
          undefined,
          activeTool as string,
          undefined,
          allVertices,
          undefined,
          undefined,
          firstConnectedAsset?.id
        )
      );

      // add buildings to buildingOverrideArray
      setBuildingOverrideArray(featureResult.invalidTypes);

      dispatch(
        completeAsset({
          connectedAssets,
          assets,
          _activeTool,
          features: featureResult?.features ?? [],
        })
      );

      // clear as we're allowing them
      setInvalidIntersections([]);
      setInvalidPoints([]);

      dispatch(setBuildingOverride(true));
    } else {
      const snap: LatLng = props[0];
      const features: FeatureData[] = props[1];
      finaliseCable(snap, features);
    }
  };

  const cablesOverMaxCableRouteLength = (line: LatLngObj[]) => {
    var totalLength: number = 0;
    var lastVertex: LatLngObj;

    // check current line
    if (line !== null) {
      totalLength = L.latLng(line[0]).distanceTo(L.latLng(line[1]));
      if (totalLength > maxCableRouteLength) return true;
    }

    // check total length of all lines
    lastVertex = meterLatLng as LatLngObj;

    vertices.map((latlng, index) => {
      var from = L.latLng(lastVertex);
      var to = L.latLng(latlng);
      totalLength += from.distanceTo(to);
      lastVertex = L.latLng(latlng);
    });

    return totalLength > maxCableRouteLength;
  };

  return (
    <>
      <Hints
        currentLatLng={currentLatLng as LatLngObj}
        withinBounds={withinBounds}
      />
      {vertices.map((latlng, index) => (
        <Marker
          key={`${latlng.lat}${latlng.lng}`}
          position={latlng}
          icon={styleIcon(index, latlng)}
        ></Marker>
      ))}
      {vertices.length > 1 && <Polyline positions={[...vertices]}></Polyline>}
      <Pane name="invalid" style={{ zIndex: 1 }}>
        {invalidIntersections.map((intersection: Position[]) => (
          <Polyline
            key={`${intersection[0]}${intersection[1]}`}
            positions={[...(intersection as [number, number][])]}
            color="red"
          ></Polyline>
        ))}
        {invalidPoints.map((intersection: Position[]) => (
          <Marker
            key={`${intersection[0]}${intersection[1]}`}
            position={intersection[0] as LatLngTuple}
          ></Marker>
        ))}
      </Pane>
      <DialogModal
        affirmDialogAction={handleDialogAffirmAction}
        dismissDialogAction={handleDialogDismissAction}
      />
      {/* Display topogrpahies for demo purposes */}
      {showGroundTypes === true && topographies && topographies.length > 0 && (
        <GeoJSON
          data={featureCollection(topographies)}
          coordsToLatLng={(coords) => {
            try {
              return new L.LatLng(coords[0], coords[1]);
            } catch {
              console.log("drawing error!");
              return new L.LatLng(0, 0);
            }
          }}
          style={(feature: any) => {
            switch (feature!.properties.TOPOGRAPHYTYPE) {
              case "ground_cover":
                return { color: "light-sea-green", opacity: 0.1, interactive: false };
              case "water_course_polygon":
                return { color: "blue", opacity: 0.1, interactive: false };
              case "transport_casing":
                if (feature!.properties.TypeCode === "Footpath") {
                  return { color: "yellow", opacity: 0.1, interactive: false };
                }
                return { color: "red", opacity: 0.5, interactive: false };
              case "land_parcel":
                return { color: "light-green", opacity: 0.1, interactive: false };
              case "building":
                return { color: "red", opacity: 0.1, interactive: false };
              case "label":
                return { color: "yellow", opacity: 0.1, interactive: false };
              case "road_junctions":
                return { color: "black", opacity: 0.1, interactive: false };
              case "road_link":
                return { color: "purple", opacity: 0.5, interactive: false };
              case "structure_polygon":
                return { color: "orange", opacity: 0.1, interactive: false };
              default:
                return { color: "green", opacity: 0.1, interactive: false };
            }
          }}
        />
      )}
    </>
  );
};

export default DrawingTool;
