import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import buffer from "@turf/buffer";
import flip from "@turf/flip";
import {
  featureCollection,
  point,
  polygon,
  Feature,
  Polygon,
  lineString,
} from "@turf/helpers";
import lineIntersect from "@turf/line-intersect";
import { LineString, Position } from "geojson";
import { LatLng } from "leaflet";
import { v4 as uuid } from "uuid";
import {
  NetworkAsset,
  LatLngObj,
  KeyValuePair,
  ExistingNetworkAsset,
  PointOfConnectionType,
  FeatureData,
} from "../models/models";
import { getNewConnectionRAG, getPillarRag, getTransformerRag } from "../services/cableService";
import {
  getGroundType,
  getIntersectingLinesFromPolygon,
  getLength,
} from "./geoSpatialHelpers";

export const matchNetworkAsset = (
  network: NetworkAsset[],
  latlng: LatLngObj,
  types = ["marker", "cable", "site"]
) => {
  return network
    .filter((f) => f.markerGeometry)
    .find(
      (n) =>
        latLngArray(latlng, n.markerGeometry as LatLngObj) &&
        types.includes(n.type)
    );
};

export const latLngArray = (e: LatLngObj, f: LatLngObj) => {
  if (!e || !f) return;
  return e.lat === f.lat && e.lng === f.lng;
};

export const matchNetworkAssetGeometry = (
  network: NetworkAsset[],
  latlng: LatLngObj,
  types = ["marker", "cable", "site"]
) => {
  const matchedNetworkAsset = matchNetworkAsset(network, latlng, types);
  return matchedNetworkAsset ? matchedNetworkAsset.markerGeometry : null;
};

export const asset = (
  id: string = uuid(),
  type: string,
  markerGeometry?: LatLngObj,
  polyGeometry?: LatLngObj[],
  name?: string,
  topographyId?: string,
  firstConnectedAsset?: string,
  secondConnectedAsset?: string,
  isConnected: boolean = false,
  pointOfConnectionType?: PointOfConnectionType,
  isConsumer: boolean = false
) => {
  return {
    id,
    type,
    markerGeometry,
    polyGeometry,
    name,
    topographyId,
    firstConnectedAsset,
    secondConnectedAsset,
    isConnected,
    pointOfConnectionType,
    isConsumer,
  };
};

export const existingAsset = (
  id: string,
  type: string,
  markerGeometry?: LatLngObj,
  polyGeometry?: LatLngObj[] | LatLngObj[][],
  name?: string,
  topography?: any,
  firstConnectedAsset?: string,
  secondConnectedAsset?: string,
  isConnected: boolean = false,
  pointOfConnectionType?: PointOfConnectionType,
  properties?: KeyValuePair[],
  isConsumer: boolean = false
) => {
  return {
    id,
    type,
    markerGeometry,
    polyGeometry,
    name,
    topography,
    firstConnectedAsset,
    secondConnectedAsset,
    isConnected,
    pointOfConnectionType,
    properties,
    isConsumer,
  };
};

export const convertFeatures = (
  features: any[],
  totalkVA: number,
  isSinglePhaseConnection: boolean
) => {

  //console.log("convertFeatures", totalkVA, isSinglePhaseConnection);

  const assets: ExistingNetworkAsset[] = [];
  features.forEach((feature) => {
    const coords = feature.geometry.type == "Point" ? convertCoordsPoint(feature.geometry.coordinates) : convertCoords(feature.geometry.coordinates);
    const properties = {
      ...feature.properties,
      ...getStyle(feature, totalkVA, isSinglePhaseConnection),
    };

    const asset = existingAsset(
      feature.id,
      feature.properties.layer
        ? ""
        : feature.id?.startsWith("Transformers")
          ? "existingtransformer"
          : feature.id?.startsWith("Pillars")
            ? "existingpillar"
            : Array.isArray(coords)
              ? "existingcable"
              : "existingpole",
      !Array.isArray(coords) ? coords : undefined,
      Array.isArray(coords) ? coords : undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      properties,
      true
    );
    assets.push(asset);
  });
  return assets;
};

export const convertCoords = (coords: LatLng | LatLng[] | LatLng[][]) => {
  const type = Array.isArray(coords)
    ? Array.isArray(coords[0])
      ? "polygon"
      : "lineString"
    : "point";

  switch (type) {
    case "point":
      return {
        lat: (coords as LatLng).lat,
        lng: (coords as LatLng).lng,
      } as LatLngObj;
    case "lineString":
      return (coords as LatLng[]).map((coord: LatLng) => ({
        lat: coord.lat,
        lng: coord.lng,
      })) as LatLngObj[];
    case "polygon":
      return (coords as LatLng[][]).map((coord) =>
        (coord as LatLng[]).map((c: LatLng) => ({
          lat: c.lat,
          lng: c.lng,
        }))
      ) as LatLngObj[][];
  }
};

export const convertCoordsPoint = (coords: any) => {
  return {
    lat: coords[0],
    lng: coords[1]
  } as LatLngObj;
};

export const getStyle = (
  feature: any,
  totalkVA: number,
  isSinglePhaseConnection: boolean
) => {

  var ragColor;
  
  if (feature.id.startsWith("Transformers")) { // Transformers
    ragColor = getTransformerRag(feature.properties, totalkVA);
  } else if (feature.id.startsWith("Pillars")) { // Pillars
    ragColor = getPillarRag(feature.properties);
  } else { // Cables
    ragColor = getNewConnectionRAG(
      feature.properties,
      totalkVA,
      isSinglePhaseConnection
    );
  }

  const styles: {
    color: string | undefined;
    dashArray: number[] | undefined;
    reason: string | undefined;
  } = { color: ragColor.color, dashArray: undefined, reason: ragColor.reason };

  if (feature.properties?.CableGroup === "service-underground" || feature.properties?.CableGroup === "service-overhead") {
    styles.color = "grey";
    styles.dashArray = [5, 5];
  }
  return styles;
};

export const completeNetworkPolygons = (network: NetworkAsset[]) => {
  return network.map((n) => ({
    ...n,
    polyGeometry:
      n.type === "cable"
        ? n.polyGeometry
        : n.polyGeometry
          ? [...n.polyGeometry, n.polyGeometry[0]]
          : undefined,
  }));
};

export const getPolygonFeatureData = (
  feature: Feature<Polygon>,
  coords: Position[]
): FeatureData => {
  const intersectingFeature = getIntersectingLinesFromPolygon(feature, coords);
  const flippedFeature = flip(featureCollection([feature])).features[0];
  const poly = polygon(flippedFeature.geometry.coordinates);

  return {
    coords: intersectingFeature,
    properties: feature.properties,
    groundType: getGroundType(feature),
    length: getLength(intersectingFeature),
    pointOfConnection: booleanPointInPolygon(coords[0], poly),
    display: "lineString",
  };
};

export const getLineStringFeatureData = (
  feature: Feature<LineString>,
  coords: Position[]
): FeatureData => {
  const intersectingPoints = lineIntersect(
    feature,
    lineString(coords)
  ).features.map((f) => f.geometry.coordinates);

  return {
    coords: intersectingPoints,
    properties: feature.properties,
    groundType: getGroundType(feature),
    display: "marker",
  };
};

export const isMarkerClicked = (snap: LatLngObj, marker: LatLngObj) => {
  const snapPoint = point([snap.lng, snap.lat]);
  const pt = point([marker.lng, marker.lat]);
  const buffered = buffer(pt, 0.00075, { units: "kilometers" });
  return booleanPointInPolygon(snapPoint, buffered);
};

export const isPolygon = (
  feature: Feature<Polygon>
): feature is Feature<Polygon> => {
  return (feature as Feature<Polygon>).geometry.type === "Polygon";
};

export const isLineString = (
  feature: Feature<LineString>
): feature is Feature<LineString> => {
  return (feature as Feature<LineString>).geometry.type === "LineString";
};
