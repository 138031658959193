import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import UndoRedoBtns from "../../features/steps/UndoRedoBtns";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectSteps, setMode } from "../../features/steps/stepsSlice";
import { selectApp, setMouseOverControl } from "../../app/appSlice";
import "./Sidebar.css";
import { tabIndexes } from "../../app/tabIndexes";

interface ISidebar {
  children: JSX.Element;
  cssClasses?: string;
}

const Sidebar = ({ children, cssClasses = "" }: ISidebar) => {
  const dispatch = useAppDispatch();
  const steps = useAppSelector(selectSteps);
  const { mode } = steps;

  const app = useAppSelector(selectApp);
  const { screenshotMode } = app;

  const handleMode = () => {
    const _mode = mode === "expanded" ? "contracted" : "expanded";
    dispatch(setMode(_mode));
  };

  const onMouseEnter = () => {
    dispatch(setMouseOverControl(true));
  };

  const onMouseLeave = () => {
    dispatch(setMouseOverControl(false));
  };

  return (
    <div
      className={`sidebar ${cssClasses} ${
        mode === "expanded" ? "expanded" : ""
      } ${screenshotMode ? "screenshot-mode" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label="sidebar"
    >
      <div className="sidebar-btns">
        <ButtonGroup>
          <Button
            variant="link"
            className={`steps-btn ${mode !== "closed" ? "bg-light" : ""}`}
            onClick={handleMode}
            aria-label="home"
            tabIndex={tabIndexes.expandCollapseSidebar}
          >
            <i
              className={`icon-${
                mode === "expanded" ? "contract-menu" : "expand-menu"
              }`}
            ></i>
          </Button>
          <UndoRedoBtns color="dark" cssClasses="m-2" />
        </ButtonGroup>
      </div>
      {children}
    </div>
  );
};

export default Sidebar;
