import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { FeatureCollection } from "geojson";


const {
  REACT_APP_HEATMAP_CONDUCTORS_SECTOR_MIN_PERCENT: conductorsSectorMin,
  REACT_APP_HEATMAP_CONDUCTORS_REGION_MIN_PERCENT: conductorsRegionMin,
  REACT_APP_HEATMAP_TRANSFORMERS_SECTOR_MIN_PERCENT: transformersSectorMin,
  REACT_APP_HEATMAP_TRANSFORMERS_REGION_MIN_PERCENT: transformersRegionMin,
} = process.env;


const getBandColors = (mode: modes) => (mode === "conductors") ? ["#BB8FCE", "#8E44AD", "#6C3483", "#4A235A"] : ["#8ea3cd", "#4366ad", "#345083", "#24365c"]; 
const getMin = (state: HeatmapState) => Number(state.mode === "conductors"
                                          ? state.percentageCalculation === "sector"
                                              ? conductorsSectorMin
                                              : conductorsRegionMin
                                          : state.percentageCalculation === "sector"
                                              ? transformersSectorMin
                                              : transformersRegionMin);
const getMax = (state: HeatmapState) => state.mode === "conductors"
                                          ? state.percentageCalculation === "sector"
                                              ? state.conductorsSectorMax
                                              : state.conductorsRegionMax
                                          : state.percentageCalculation === "sector"
                                              ? state.transformersSectorMax
                                              : state.transformersRegionMax;
const getBandSize = (state: HeatmapState) => (state.min !== undefined && state.max !== undefined && (state.max > state.min)) ? ((state.max - state.min) / state.bandColors.length) : undefined;

const MODE_DEFAULT: modes = "conductors";
const PERCENTAGE_CALCULATION_DEFAULT: percentageCalculations = "sector";


const {
  REACT_APP_API_URL: apiUrl,
} = process.env;

export interface HeatmapState {
  postcodeStats: FeatureCollection | undefined;
  conductorsSectorMax?: number;
  conductorsRegionMax?: number;
  transformersSectorMax?: number;
  transformersRegionMax?: number;
  mode: modes;
  percentageCalculation: percentageCalculations;
  bandColors: string[];
  min?: number;
  max?: number;
  bandSize?: number;
}

const initialState: HeatmapState = {
  postcodeStats: undefined,
  conductorsSectorMax: undefined,
  conductorsRegionMax: undefined,
  transformersSectorMax: undefined,
  transformersRegionMax: undefined,
  mode: MODE_DEFAULT,
  percentageCalculation: PERCENTAGE_CALCULATION_DEFAULT,
  bandColors: getBandColors(MODE_DEFAULT),
  min: undefined,
  max: undefined,
  bandSize: undefined,
};

export type modes = "conductors" | "transformers";
export type percentageCalculations = "sector" | "region";

export const heatmapSlice = createSlice({
  name: "heatmap",
  initialState,
  reducers: {
    updatePostCodeStats: (state, action: PayloadAction<FeatureCollection | undefined>) => {

      let conductorsSectorMax: number | undefined = undefined;
      let conductorsRegionMax: number | undefined = undefined;
      let transformersSectorMax: number | undefined = undefined;
      let transformersRegionMax: number | undefined = undefined;

      action.payload?.features.forEach((feature) => {
        if (feature.properties) {
          const conductorsSectorPercentageValue : number = feature.properties["GreyConductorsPercentage"] as number;
          const conductorsRegionPercentageValue : number = feature.properties["GreyConductorsAsPercentageOfDNOTotal"] as number;
          const transformersSectorPercentageValue : number = feature.properties["GreyTransformersPercentage"] as number;
          const transformersRegionPercentageValue : number = feature.properties["GreyTransformersAsPercentageOfDNOTotal"] as number;
    
          conductorsSectorMax = (conductorsSectorMax !== undefined) ? Math.max(conductorsSectorMax, conductorsSectorPercentageValue) : conductorsSectorPercentageValue;
          conductorsRegionMax = (conductorsRegionMax !== undefined) ? Math.max(conductorsRegionMax, conductorsRegionPercentageValue) : conductorsRegionPercentageValue;
          transformersSectorMax = (transformersSectorMax !== undefined) ? Math.max(transformersSectorMax, transformersSectorPercentageValue) : transformersSectorPercentageValue;
          transformersRegionMax = (transformersRegionMax !== undefined) ? Math.max(transformersRegionMax, transformersRegionPercentageValue) : transformersRegionPercentageValue;

        }
      });

      state.conductorsSectorMax = conductorsSectorMax;
      state.conductorsRegionMax = conductorsRegionMax;
      state.transformersSectorMax = transformersSectorMax;
      state.transformersRegionMax = transformersRegionMax;
      state.min = getMin(state);
      state.max = getMax(state);
      state.bandSize = getBandSize(state);

      state.postcodeStats = action.payload;
    },
    clearHeatmap: (state) => {
      state = initialState;
    },
    updateMode: (state, action: PayloadAction<modes>) => {
      state.mode = action.payload;
      state.bandColors = getBandColors(action.payload);
      state.min = getMin(state);
      state.max = getMax(state);
      state.bandSize = getBandSize(state);
    },
    updatePercentageCalculation: (state, action: PayloadAction<percentageCalculations>) => {
      state.percentageCalculation = action.payload;
      state.min = getMin(state);
      state.max = getMax(state);
      state.bandSize = getBandSize(state);
    },
  },
});

export const {
  updatePostCodeStats,
  clearHeatmap,
  updateMode,
  updatePercentageCalculation,
} = heatmapSlice.actions;

export const selectHeatmap = (state: RootState) =>
  state.heatmap;

export default heatmapSlice.reducer;
