import L, { LatLngBounds, LatLngTuple } from "leaflet";

const getBounds = (bounds: LatLngBounds) => {
    return {
      southLat: bounds.getSouth(),
      westLng: bounds.getWest(),
      northLat: bounds.getNorth(),
      eastLng: bounds.getEast(),
    };
  };

type ResponseTuple = {
    bounds: LatLngBounds, 
    url: string,
    response :any
}

const responseCacheMap: Map<string, ResponseTuple> = new Map();
const requestCacheMap: Map<string, any> = new Map();

const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

export const getCachedResponse = async (bounds: LatLngBounds, url: string) => {

    //console.log("getCachedResponse", responseCacheMap.size);

    const bb = getBounds(bounds);
    const fullUrl = `${url}&bbox=${bb.southLat},${bb.westLng},${bb.northLat},${bb.eastLng}`;
    
    // if already retrieved, fetch from cache
    if (responseCacheMap.has(fullUrl)) {
        //console.log("cached response retrieved (url)");
        return JSON.parse(JSON.stringify(responseCacheMap.get(fullUrl)?.response));
    }

    // Not too sure if this works fully
    // responseCacheMap.forEach((r: any) => {
    //     if(r.url === url && r.bounds.contains(bounds)) {
    //         console.log("cached response retrieved (bounds)", r.url, url, r.bounds, bounds);            
    //         return responseCacheMap.get(fullUrl)?.response;
    //     }
    // });

    // if already waiting for this url then wait till that request has completed
    let count = 0;
    while(requestCacheMap.has(fullUrl))
    {
        //console.log("waiting for response...", url);
        await timeout(100);
        // protect against something going wrong
        if(++count > 50) {
            break;
        }
    }
    
    // if it's now been retrieved, fetch from cache
    if (responseCacheMap.has(fullUrl)) {
        //console.log("cached response retrieved");
        return responseCacheMap.get(fullUrl)?.response;
    }
    
    //console.log("response needs retrieving", url);
    requestCacheMap.set(fullUrl, false);
    return null;
};

export const setCachedResponse = async (bounds: LatLngBounds, url: string, response: any) => {
    
    //console.log("setCachedResponse", responseCacheMap.size);

    const bb = getBounds(bounds);
    const fullUrl = `${url}&bbox=${bb.southLat},${bb.westLng},${bb.northLat},${bb.eastLng}`;
    requestCacheMap.delete(fullUrl);

    const fixedResponse = JSON.parse(JSON.stringify(response));

    if (!responseCacheMap.has(fullUrl)) {
        responseCacheMap.set(fullUrl, {bounds, url, response:fixedResponse});
        //console.log("cached response set", fullUrl);
    } else {
        //console.log("cached response already set!", fullUrl);
    }
};
