import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { ActionCreators } from "redux-undo";
import Button from "react-bootstrap/Button";
import { selectSteps } from "./stepsSlice";
import { hasPast, hasFuture } from "../networkCreator/networkCreatorSlice";
import { tabIndexes } from "../../app/tabIndexes";

export interface IUndoRedoBtns {
  size?: "sm" | "lg" | undefined;
  color: string;
  cssClasses: string;
}

const UndoRedoBtns = ({
  size = undefined,
  color,
  cssClasses,
}: IUndoRedoBtns) => {
  const past = useAppSelector(hasPast);
  const future = useAppSelector(hasFuture);
  const dispatch = useAppDispatch();

  const steps = useAppSelector(selectSteps);
  const { mode } = steps;

  return (
    <>
      <Button
        variant={color}
        aria-label="undo"
        className="my-2 ml-2 mr-0 undo-redo"
        onClick={() => dispatch(ActionCreators.undo())}
        disabled={!past}
        tabIndex={tabIndexes.undo}
      >
        <i className="icon-undo-solid"></i>
      </Button>
      <Button 
          variant={color}
          aria-label="redo"
          className="my-2 mr-2 ml-0 undo-redo"
          onClick={() => dispatch(ActionCreators.redo())}
          disabled={!future}
          tabIndex={tabIndexes.redo}
        >
          <i className="icon-redo-solid"></i>
        </Button>
    </>
  );
};

export default UndoRedoBtns;
