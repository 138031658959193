import { Router, RouterProvider, createBrowserRouter } from "react-router-dom";
import LeafletMap from "../components/leafletMap/LeafletMap";
import "./App.css";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

import Heatmap from "../components/heatmap/heatmap";

const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;
if (connectionString !== undefined) {
    //console.log("envConnectionString=", connectionString, process.env);
    const browserHistory = createBrowserHistory();

    var clickPluginInstance = new ClickAnalyticsPlugin();
    const clickPluginConfig = {
        autoCapture: true,
    };

    var reactPlugin = new ReactPlugin();

    var appInsights = new ApplicationInsights({
        config: {
            connectionString: connectionString,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin, clickPluginInstance],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
                [clickPluginInstance.identifier]: clickPluginConfig,
            },
        },
    });
    appInsights.loadAppInsights();
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <LeafletMap marginX="0" marginY="0" />,
    },
    {
        path: "/heatmap",
        element: <Heatmap marginX="0" marginY="0" />,
    },
]);
function App() {
    return <RouterProvider router={router} />;
}

export default App;
