import { useAppSelector } from "../app/hooks";
import { selectNetworkCreator } from "../features/networkCreator/networkCreatorSlice";
import { selectExistingNetwork, } from "../features/existingNetwork/existingNetworkSlice";

const useNetwork = () => {
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { network } = networkCreator;
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const { topographies } = existingNetwork;

  const isNetworkConnected = [...network]
    .filter((a) => a.type === "cable")
    .find((b) => b.isConnected);

  const hasGroundtypes = topographies.length > 0;

  return {
    isNetworkConnected, hasGroundtypes
  };
}

export default useNetwork;
