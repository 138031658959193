import { useEffect } from "react";
import { MapContainer,  ScaleControl, TileLayer } from "react-leaflet";
import Control from "../control/Control";
import ZoomControl from "../zoomControl/ZoomControl";
import PostCodeStats from "../../features/heatmap/postcodeStats";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { modes, percentageCalculations, selectHeatmap, updateMode, updatePercentageCalculation } from "../../features/heatmap/heatmapSlice";
import Console, { ConsoleOptions } from "../console/Console";
import HeatmapLegend from "../../features/heatmap/heatmapLegend";
import Overlay from "../overlay/Overlay";
import L from "leaflet";

interface IHeatmapProps {
    marginX: string;
    marginY: string;
}
  
const {
    REACT_APP_API_URL: apiUrl,
  } = process.env;

const Heatmap = (props: IHeatmapProps) => {
    const dispatch = useAppDispatch();
    const heatmap = useAppSelector(selectHeatmap);
    const { postcodeStats, mode, percentageCalculation } = heatmap;
    
    useEffect(() => {
      window.addEventListener("message", handleObj, false);

      return () => {
        window.removeEventListener("message", handleObj, false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleObj = async (e: any) => {
      if (e.data.mode) {
        dispatch(updateMode(e.data.mode as modes));
      }
      if (e.data.percentageCalculation) {
        dispatch(updatePercentageCalculation(e.data.percentageCalculation as percentageCalculations));
      }
    };
    
    const northernIrelandBounds = new L.LatLngBounds({lat: 53.81038242731131, lng: -10.173339843750002},{lat: 55.39471190628709, lng: -3.9221191406250004});


    return (
        <>
          <MapContainer
            id="map"
            style={{
              width: `calc(100% - ${props.marginX}rem)`,
              height: `calc(100% - ${props.marginY}rem)`,
            }}
            center={[54.6105724,-7.0462907]}
            zoom={8}
            minZoom={8}
            maxZoom={14}
            scrollWheelZoom={true}
            zoomControl={false}
            doubleClickZoom={false}
            tap={false}
          >
            <TileLayer
              id="tilelayer"
              attribution='&copy; <a href="https://www.nidirect.gov.uk/articles/osni-fusion" target="new">OSNI Fusion</a>'
              url={apiUrl + "/tile/{z}/{x}/{y}.png"}
              maxZoom={14}
              eventHandlers={{
                //load: onTileLoad,
                //loading: onTileLoading
              }}
            />
            <PostCodeStats bounds={northernIrelandBounds} />
            <Control positionY="top" positionX="right" size="md">
              <Console
                availableOptions={ConsoleOptions.legend}
                defaultOption={ConsoleOptions.legend}
                legend={<HeatmapLegend />}
              />
            </Control>
            <Control positionY="bottom" positionX="right" size="md" cssClasses="control-behind-guidance">
                <ScaleControl imperial={false} position="bottomright" />
                <ZoomControl color="dark" cssClasses="iosBoxShadow mb-3" />
            </Control>
          </MapContainer>
          <Overlay show={postcodeStats === undefined}></Overlay>
        </>
      );

}

export default Heatmap;