import { useState, useContext, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import styles from "./StepItem.module.css";

export interface IToggle {
  children: JSX.Element | false;
  eventKey: string;
  mode: string;
}

const Toggle = ({ children, eventKey, mode }: IToggle) => {
  const currentEventKey = useContext(AccordionContext);

  useEffect(() => {
    setDisabled(false);
    if (mode !== "expanded") {
      if (currentEventKey === eventKey) {
        if (inputRef.current !== null) {
          inputRef.current.click();
        }
      }
      setDisabled(true);
    }
  }, [mode, currentEventKey, eventKey]);

  const [disabled, setDisabled] = useState<boolean>(false);
  const inputRef = useRef<HTMLButtonElement>(null);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    mode === "expanded" ? eventKey : currentEventKey
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <>
      <Button
        ref={inputRef}
        type="button"
        variant=""
        className={`${styles.stepItemToggle} ${
          mode === "expanded" ? `${styles.expanded}` : ""
        } d-inline-flex align-items-center`}
        aria-expanded={isCurrentEventKey}
        onClick={decoratedOnClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </>
  );
};

export default Toggle;
