import axios from "axios";

const {
  REACT_APP_API_URL: apiUrl,
} = process.env;

export const getPackage = async (obj: any) => {
  const url = apiUrl + "/Calculate" || "";

  return axios
    .post(url, obj, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {      
      return error.response?.data?.correlationId;
    });
};
