import Conductors from "../features/existingNetwork/Conductors";
import Pillars from "../features/existingNetwork/Pillars";
import Transformers from "../features/existingNetwork/Transformers";

const {
  REACT_APP_GEOSERVER: geoserver,
  REACT_APP_GEOSERVER_WORKSPACE: geoserverWorkspace,
} = process.env;

const url = (layer: string) => {
  return `${geoserver}/${geoserverWorkspace}/ows?service=WFS&version=1.1.0&request=GetFeature&typename=${geoserverWorkspace}:${layer}&outputFormat=application%2Fjson&srsname=EPSG:4326`;
};

const conductingSections = url("AcLineSegment");
const overheadPoles = url("JunctionIsPole");
const transformers = url("TransformersWithMaxUtilisationPercent");
const pillars = url("PillarsWithTransformerMaxUtilisationPercent");

const Controls = () => {
  return (
    <>
      <Conductors name="conductingSections" url={conductingSections} />
      <Conductors name="overheadPoles" url={overheadPoles} secondUrl={conductingSections} />
      <Transformers url={transformers} />
      <Pillars url={pillars}/>
    </>
  );
};

export default Controls;
