import React, { useState, useEffect } from "react";
import { useMap, useMapEvent } from "react-leaflet";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { tabIndexes } from "../../app/tabIndexes";

export interface IZoomControl {
  size?: "sm" | "lg" | undefined;
  color: string;
  cssClasses: string;
}

const ZoomControl = ({ size, color, cssClasses }: IZoomControl) => {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());

  //useEffect(() => {
  //  map.setZoom(zoomLevel, { animate: false });
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [zoomLevel, map]);

  useMapEvent("zoomend", () => {
    setZoomLevel(map.getZoom());
  });

  const setZoom = (value: number) => {
    map.setZoom(zoomLevel + value, { animate: false });
  };

  return (
    <ButtonGroup
      vertical
      size={size}
      className={cssClasses}
      aria-label="zoom buttons"
    >
      <Button
        aria-label="zoom-up"
        variant={color}
        disabled={zoomLevel === map.getMaxZoom()}
        onClick={() => setZoom(1)}
        className="zoom"
        tabIndex={tabIndexes.zoomIn}
      >
        <i className="icon-plus-solid"></i>
      </Button>
      <Button
        aria-label="zoom-down"
        variant={color}
        disabled={zoomLevel === map.getMinZoom()}
        onClick={() => setZoom(-1)}
        className="zoom"
        tabIndex={tabIndexes.zoomOut}
      >
        <i className="icon-minus-solid"></i>
      </Button>
    </ButtonGroup>
  );
};

export default ZoomControl;
