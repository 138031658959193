import { Map } from "leaflet";
import { getGeoJsonWithBounds } from "../services/geoServerService";

export const mapCablePropertiesToJunction = async (
  map: Map,
  response: any,
  response2: any
) => {
  let features: any[] = [];
  response.features.forEach((r: any) => {
    const conductingSection = response2.features.find(
      (f: any) => f.properties.EndAssetId === r.properties.JunctionId
    );

    if (!conductingSection) return response.features;

    const {
      TransformerMaxUtilisationPercent,
      MaximumBranchUtilisationUpstreamPercent,
      MaxVoltDropPercent,
      SourceLoopOperatingImpedance,
      TransformerRatingkVA,
      SystemVoltage,
      CableRatingAmps,
    } = conductingSection.properties;

    const properties = {
      ...r.properties,
      TransformerMaxUtilisationPercent: TransformerMaxUtilisationPercent,
      MaximumBranchUtilisationUpstreamPercent:
        MaximumBranchUtilisationUpstreamPercent,
      MaxVoltDropPercent: MaxVoltDropPercent,
      SourceLoopOperatingImpedance: SourceLoopOperatingImpedance,
      TransformerRatingkVA: TransformerRatingkVA,
      SystemVoltage: SystemVoltage,
      CableRatingAmps: CableRatingAmps,
    };
    const feature = { ...r, properties };
    features = [...features, feature];
  });
  return features;
};
