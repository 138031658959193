import L, { LatLngTuple } from "leaflet";
import { CircleMarker, Polyline, useMap, useMapEvents, Popup, MapContainer } from "react-leaflet";
import { lineString } from "@turf/helpers";
import length from "@turf/length";
import { selectApp } from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ExistingNetworkAsset, LatLngObj } from "../../models/models";
import { getGeoJsonWithBounds } from "../../services/geoServerService";
import { mapCablePropertiesToJunction } from "../../utils/mapCablePropertiesToJunction";
import { convertFeatures } from "../../utils/networkUtils";
import { reverseCoords } from "../../utils/reverseCoords";
import {
  updateTransformers,
  selectExistingNetwork,
  setSelectedCable,
  setSelectedCableWeight
} from "./existingNetworkSlice";
import { useState } from "react";
import { getCachedResponse, setCachedResponse } from "../../utils/responseCache";
import React from "react";

interface ITransformers {
  url: string;
}

let abortController = new AbortController();

const Transformers = ({ url }: ITransformers) => {
  const map = useMap();
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const app = useAppSelector(selectApp);
  const { totalkVA, singlePhaseConnections, threePhaseConnections, userRole } = app.inputs;
  const { transformers, selectedCable, selectedCableWeight } = existingNetwork;
  const dispatch = useAppDispatch();

  const { submit } = app;

  const singlePhaseConnection = singlePhaseConnections === 1 && !threePhaseConnections;

  const [currentMapBounds, setCurrentMapBounds] = useState<L.LatLngBounds | undefined>(undefined);

  const showPopupFields = process.env.REACT_APP_POPUP_TRANSFORMER_FIELDS?.split(",");

  const updateFeatures = async () => {

    abortController.abort();
    abortController = new AbortController();

    //document.dispatchEvent(new Event("busyNetwork"));

    const bounds: any = map.getBounds();

    let response = await getCachedResponse(bounds, url);
    if (response === null) {
      //document.dispatchEvent(new Event("busyNetwork"));
      response = await getGeoJsonWithBounds(map.getBounds(), url, abortController);
      if (response === undefined) {
        //document.dispatchEvent(new Event("notbusyNetwork"));
        return console.log("Failed to get json from ", url);
      } else if (response === null) {
        return; // console.log("null json from ", url);
      }
      await setCachedResponse(bounds, url, response);
    }

    //---------------------------------------
    //AGW sanitize coordinates!
    //---------------------------------------
    response.features.forEach((feature: any) => {
      // multiple geometries?
      if (feature.geometry.type === "GeometryCollection") {
        try {
          let polygon = feature.geometry.geometries.filter((x: { type: string | string[]; }) => x.type.includes("Polygon"));
          if (polygon !== undefined) {
            feature.geometry.coordinates = [1];
            feature.geometry.coordinates[0] = polygon[0].coordinates;
            feature.geometry.type = "MultiPolygon";
            feature.geometry.geometries = null;
          }
        } catch {
          console.log("invalid transformer!");
        }
      }

      // AGW fix for database issue
      let fix = false;
      if(feature.geometry.type === "Polygon") {
        fix = true;
        feature.geometry.type = "MultiPolygon";
      }     
      if (feature.geometry.coordinates.length === 1) {
        const coords = feature.geometry.coordinates[0];
        feature.geometry.coordinates = fix === true ? coords : coords[0];
      } else if (feature.geometry.coordinates.length > 1) {
        let xCoords: any = [];
        feature.geometry.coordinates.forEach((coords: any) => {
          coords.forEach((coord: any) => {
            coord.forEach((coordx: any) => {
              xCoords.push(coordx);
            });
          });
        });
        feature.geometry.coordinates = xCoords;
      }
    });

    const reversedFeatureCollection = reverseCoords(response);

    const convertedFeatures = convertFeatures(
      reversedFeatureCollection.features,
      totalkVA,
      singlePhaseConnection
    );

    dispatch(updateTransformers(convertedFeatures));

    //document.dispatchEvent(new Event("notbusyNetwork"));    
  };

  const ShowField = (field: string) => {
    return showPopupFields?.find((x) => x.startsWith(field));
  };

  const DisplayField = (key: string, value: any) => {
    if (value === null || value === undefined || value === "")
      return "null";
    return value === false ? "false" : value === true ? "true" : DisplayFormatted(key, value, showPopupFields);
  };

  const DisplayFormatted = (key: string, value: any, fields: string[] | undefined) => {
    let formatting = fields?.find((x) => x.startsWith(key));
    if (formatting !== undefined) {
      let fmtArray = formatting?.split("|");
      if (fmtArray !== undefined) {
        if (fmtArray[1] === "decimal") {
          if (fmtArray[2] !== undefined && fmtArray[2] == "%") {
            if (fmtArray[3] !== undefined) {
              return (parseFloat(value) * parseFloat(fmtArray[3])).toFixed(3) + "%";
            }
            return parseFloat(value).toFixed(3) + "%";
          }
          if (fmtArray[2] !== undefined) {
            return parseFloat(value).toFixed(3) + fmtArray[2];
          }
          return parseFloat(value).toFixed(3);
        } else if (fmtArray[1] === "int") {
          if (fmtArray[2] !== undefined) {
            return value + fmtArray[2];
          }
          return value;
        }
      }
    }
    return value;
  }

  useMapEvents({
    async moveend() {
      if (transformers.length === 0) {
        //console.log("No transformers so resetting current map bounds");
        setCurrentMapBounds(undefined);
      } else {
        const newMapBounds = map.getBounds();
        if (currentMapBounds !== undefined && currentMapBounds.contains(newMapBounds)) {
          //console.log("already got transformers!");
          return;
        }
        setCurrentMapBounds(newMapBounds);
      }

      if (!submit) {
        //console.log("refresh transformers..");
        await updateFeatures();
      }
    }
  });

  const selectCable = (cable: any) => {
    //console.log("selectCable", cable._path._leaflet_id, cable);
    dispatch(setSelectedCable(cable));
    dispatch(setSelectedCableWeight(cable.options.weight));
    cable.setStyle({ weight: cable.options.weight + 3 });
  }

  const resetCable = () => {
    if (selectedCable !== undefined) {
      //console.log("resetCable", selectedCable._path._leaflet_id, selectedCableWeight);
      selectedCable?.setStyle({ weight: selectedCableWeight });
    } else {
      //console.log("resetCable", selectedCable);
    }
  }

  const handleClick = (e: any) => {
    if (userRole === "internal") {
      resetCable();
      selectCable(e.target);
      // console.table({
      //   ...{ id: feature.id },
      //   ...feature.properties,
      // });
    }
  }

  return (
    <>
      {transformers.length > 0 &&
        transformers.map((feature: any) => (
          <Polyline
            key={feature.id}
            positions={feature.polyGeometry}
            color={feature.properties.color}
            weight={2}
            fill={true}
            fillColor={feature.properties.color}
            fillOpacity={0.9}
            dashArray={feature.properties.dashArray}
            interactive={feature.properties && userRole === "internal" ? true : false}
            eventHandlers={{
              click: handleClick.bind(this),
              popupclose: resetCable
            }}
          >
            {feature.properties && userRole === "internal" && (
              <Popup className="leaflet-popup leaflet-conductor-popup" maxHeight={200} maxWidth={800} autoPan={false} keepInView={true} closeOnClick={true}>
                <table>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <b>Transformer</b>
                        <hr className="leaflet-popup-separator" />
                      </td>
                    </tr>
                    {Object.keys(feature.properties).map((key) => {
                      if (ShowField(key)) {
                        if (key === "reason") {
                          let reasonsArray = feature.properties[key] as string;
                          let reasons = reasonsArray.split("|");
                          const streetView = feature.polyGeometry != null && feature.polyGeometry.length > 0 ? feature.polyGeometry[0] : null;
                          const streetViewUrl = "https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=" + streetView?.lat + "," + streetView?.lng;
                          return (
                            <React.Fragment key={key}>
                              <tr key={key}>
                                <td colSpan={2}>
                                  <hr className="leaflet-popup-separator" />
                                  <b>Debugging info</b>
                                </td>
                              </tr>
                              {reasons.map((element: any) => (
                                <tr key={key + element}>
                                  <td colSpan={2}>{element}</td>
                                </tr>
                              ))}
                              {streetView && (
                                <tr key={key + "location"}>
                                  <td colSpan={2}>
                                    <hr className="leaflet-popup-separator" />
                                    <a onClick={(e) => navigator.clipboard.writeText(streetView?.lat + "," + streetView?.lng)}><img src="/images/copy.png" onClick={(e) => { e.currentTarget.title = "copied"; e.currentTarget.src = '/images/copied.png'; }} className="leaflet-popup-copy-icon" title="Copy location to clipboard" /></a>
                                    <a href={streetViewUrl} target="_blank"><img src="/images/streetview.png" className="leaflet-popup-streetview-icon" title="Street View" /></a>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <tr key={key}>
                              <td colSpan={2}>
                                {key} <b>{DisplayField(key, feature.properties[key])}</b>
                              </td>
                            </tr>
                          );
                        }
                      }
                    })}
                  </tbody>
                </table>
              </Popup>
            )}
          </Polyline>
        ))}
    </>
  );
};

export default Transformers;
