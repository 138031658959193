import { Marker, Polygon } from "react-leaflet";
import { iconMarker } from "../../utils/iconMarker";
import "../../utils/iconMarker.css";
import { NetworkAsset } from "../../models/models";
import MeasuredPolyline from "../../measurePath/MeasuredPolyline";

interface IAsset {
  key: string;
  asset: NetworkAsset;
  color: string;
}

const Asset = ({ asset, color }: IAsset) => {
  const styleIcon = () => {
    const iconColor = asset.isConnected ? "iconConnected" : "iconDark";
    const iconSize = asset.name === "joint" ? 0.75 : 1;
    return iconMarker(iconSize, iconColor, asset.name);
  };

  return (
    <>
      {asset.type === "cable" && (
        <MeasuredPolyline
          positions={asset.polyGeometry as L.LatLng[]}
          pathOptions={{
            color: asset.isConnected ? "#3388ff" : "#343a40",
          }}
          showMeasurements={true}
        ></MeasuredPolyline>
      )}
      {asset.type === "marker" && (
        <Marker
          position={asset.markerGeometry as L.LatLng}
          icon={styleIcon()}
          zIndexOffset={9999}
        ></Marker>
      )}
      {asset.type === "site" && (
        <>
          <Polygon
            positions={asset.polyGeometry as L.LatLng[]}
            pathOptions={{
              color: "#343a40",
              fillOpacity: 0,
              dashArray: "5 10",
              weight: 2,
            }}
          ></Polygon>
          {asset.markerGeometry && (
            <Marker
              position={asset.markerGeometry as L.LatLng}
              icon={styleIcon()}
              zIndexOffset={9999}
            ></Marker>
          )}
        </>
      )}
    </>
  );
};

export default Asset;
