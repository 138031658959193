import { useAppSelector } from "../../app/hooks";
import styles from "./heatmapLegend.module.css";
import {Property} from "csstype"
import { percentageCalculations, selectHeatmap } from "./heatmapSlice";

const {
    REACT_APP_HEATMAP_SECTOR_DECIMAL_PLACES: sectorDecimalPlacesString,
    REACT_APP_HEATMAP_REGION_DECIMAL_PLACES: regionDecimalPlacesString,
  } = process.env;

const sectorDecimalPlaces = Number(sectorDecimalPlacesString);
const regionDecimalPlaces = Number(regionDecimalPlacesString);


export interface IHeatmapLegendProps {

}

interface IHeatmapLegendItemProps {min: number, max: number, color: Property.Color, percentageCalculation: percentageCalculations}

const HeatmapLegendItem = (props: IHeatmapLegendItemProps) => {
    const heatmap = useAppSelector(selectHeatmap);
    const { percentageCalculation } = heatmap;

    return <li>
        <span>{props.min.toFixed(percentageCalculation === "sector" ? sectorDecimalPlaces : regionDecimalPlaces) + "%"} - {props.max.toFixed(percentageCalculation === "sector" ? sectorDecimalPlaces : regionDecimalPlaces) + "%"}</span>
        <div className={styles.legendColor} style={{
            backgroundColor: props.color,
            }}>            
        </div>
    </li>
}

const HeatmapLegend = (props: IHeatmapLegendProps) => { 
    
  const heatmap = useAppSelector(selectHeatmap);
  const { postcodeStats, bandColors, min, bandSize, mode, percentageCalculation } = heatmap;

    return <div className={styles.legend}>
        <h6 className={styles.header}>Legend</h6>

        {(postcodeStats === undefined) && (
            <span>Loading...</span>
        )}

        {(postcodeStats !== undefined) && (bandColors !== undefined) && (min !== undefined) && (
            <>
                <p>Percentage of grey {mode}<br />{percentageCalculation === "sector" ? "within each postcode sector" : "across entire NI region"}</p>
                <ul className={styles.customList}>
                    <HeatmapLegendItem min={0} max={min} color={""} percentageCalculation={percentageCalculation} />
                    {
                        (bandSize !== undefined) && (
                            bandColors.map((val, idx) => 
                            <HeatmapLegendItem key={idx} min={idx === 0 ? min : min + (idx * bandSize)} max={min + ((idx + 1) * bandSize)} color={val} percentageCalculation={percentageCalculation} />
                        ))
                    }            
                </ul>
            </>
        )}

        
    </div>;
}

export default HeatmapLegend