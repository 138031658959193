import { Map } from "leaflet";
import { polygon } from "@turf/helpers";
import centroid from "@turf/centroid";
import { LatLngObj, NetworkAsset } from "../models/models";
import get, { AxiosResponse } from "axios";
import { GT_OSGB } from "./geoTools2";

export const postcodeToSetView = (map: Map, searchValue: string) => {
  return new Promise<void>((resolve, reject) =>
  {
    const find = async () => {
      get(`https://api.postcodes.io/postcodes/${searchValue}`)
      .then(
        (response: AxiosResponse<any>) => {
          const { latitude: lat, longitude: lng } = response.data.result;
          map.setView({ lat, lng }, 18); // added reset zoom to 18
          resolve();
        }
      )
      .catch((error: any) => {
        reject(error);
      });
    };
    find();
  });
};

export const gridRefToSetView = (map: Map, searchValue: string) => {
  const osgb = new GT_OSGB();
  var prefixExpression =
    "^((([sS]|[nN])[a-hA-Hj-zJ-Z])|(([tT]|[oO])[abfglmqrvwABFGLMQRVW])|([hH][l-zL-Z])|([jJ][lmqrvwLMQRVW]))$";
  var gridRef = searchValue.replace(/ |,/g, "");
  if (gridRef.substring(0, 2).match(prefixExpression)) {
    osgb.parseGridRef(gridRef.toUpperCase());
  } else {
    osgb.setGridCoordinates(
      gridRef.substring(0, gridRef.length / 2),
      gridRef.substring(gridRef.length / 2)
    );
  }

  const wgs84 = osgb.getWGS84();
  map.setView([wgs84.latitude, wgs84.longitude]);
};

export const latLngToSetView = (map: Map, searchValue: string) => {
  const latLngArr = searchValue.split(",");
  const lat = parseFloat(latLngArr[0]);
  const lng = parseFloat(latLngArr[1]);
  map.setView({ lat, lng });
};

export const polygonCentroidToSetView = (map: Map, existingStudy: any) => {
  const yo = existingStudy.network.find(
    (asset: NetworkAsset) => asset.type === "site"
  ).polyGeometry;
  let yo2 = yo.map((y: LatLngObj) => [y.lng, y.lat]);
  yo2 = [...yo2, yo2[0]];

  var _polygon = polygon([yo2]);
  var _centroid = centroid(_polygon);
  map.setView([
    _centroid.geometry.coordinates[1],
    _centroid.geometry.coordinates[0],
  ]);
};
