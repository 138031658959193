import { CSSTransition } from "react-transition-group";
import Toggle from "./Toggle";
import { useAppSelector } from "../../app/hooks";
import { selectSteps } from "./stepsSlice";
import styles from "./StepItem.module.css";
import { useRef } from "react";

export interface IStepItem {
  children: JSX.Element;
  item: any;
  toggle: boolean;
}

const StepItem = ({ children, item, toggle }: IStepItem) => {
  const steps = useAppSelector(selectSteps);
  const { mode } = steps;
  const nodeRef = useRef(null);
  
  const transition = (
    <CSSTransition
      nodeRef={nodeRef}
      in={mode === "expanded"}
      timeout={150}
      classNames="step-text"
      unmountOnExit
    >
      <span ref={nodeRef} aria-label={`${item.type || item.label}${item.id}`}>
        {(item.type || item.label).replace("-", " ")}
      </span>
    </CSSTransition>
  );

  return (
    <div className={`d-flex align-items-center justify-content-between p-2`}>
      {toggle ? (
        <Toggle eventKey={item.id} mode={mode}>
          {transition}
        </Toggle>
      ) : (
        <div className={styles.stepItem}>
          <span className={styles.square}>{item.id}</span>
          {transition}
        </div>
      )}
      {children}
    </div>
  );
};

export default StepItem;
