import styles from "./Legend.module.css";

const Legend = () => {
  return (
    <div className={styles.legend}>
      <h6 className={styles.header}>Legend</h6>
      <ul className={styles.customList}>
        <li className={styles.listItemLine}>Electricity Cable</li>
        <li className={styles.listItemDashedLine}>Service Cable</li>
        <li className={styles.listItemCircle}>Overhead Pole</li>
        <li className={styles.listItemGreen}>Connection possible</li>
        <li className={styles.listItemOrange}>Connection may be possible</li>
        <li className={styles.listItemRed}>Connection not possible</li>
      </ul>
    </div>
  );
};

export default Legend;
