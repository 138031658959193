import { ScaleControl } from "react-leaflet";
import Control from "../components/control/Control";
import Console from "../components/console/Console";
import ZoomControl from "../components/zoomControl/ZoomControl";

const Controls = () => {
  return (
    <>
      <Control positionY="top" positionX="right" size="md">
        <Console />
      </Control>
      <Control positionY="bottom" positionX="right" size="md" cssClasses="control-behind-guidance">
        <ScaleControl imperial={false} position="bottomright" />
        <ZoomControl color="dark" cssClasses="iosBoxShadow mb-3" />
      </Control>
    </>
  );
};

export default Controls;
