import { CSSTransition } from "react-transition-group";
import StepItem from "./StepItem";
import styles from "./Step.module.css";
import React from "react";

export interface IStep {
  children: JSX.Element;
  stepBody?: JSX.Element;
  stepNumber: number;
  name: string;
  cssClasses: string;
  overlay: boolean;
}

const Step = ({
  children,
  stepBody,
  stepNumber,
  name,
  cssClasses,
  overlay,
}: IStep) => {
  const nodeRef = React.useRef(null);
  return (
    <div className={`${styles.step} ${cssClasses}`}>
      <StepItem item={{ id: stepNumber, label: name }} toggle={false}>
        {children}
      </StepItem>
      {stepBody}
      <hr />
      <CSSTransition 
        nodeRef={nodeRef}
        in={overlay}
        timeout={150}
        classNames="overlay"
        unmountOnExit
      >
        <div className={styles.overlay} ref={nodeRef} />
      </CSSTransition>
    </div>
  );
};

export default Step;
