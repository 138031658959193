import { Pane } from "react-leaflet";

import { useAppSelector } from "../../app/hooks";
import Asset from "../../components/asset/Asset";
import Feeders from "../../containers/Feeders";
import { NetworkAsset } from "../../models/models";
import DrawingTool from "./DrawingTool";
import { selectNetworkCreator } from "./networkCreatorSlice";

const NetworkCreator = () => {
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { network } = networkCreator;

  const setColor = (asset: NetworkAsset) => {
    const color: string = asset.markerGeometry ? "#343a40" : "#3388ff";
    return color;
  };

  return (
    <Pane name="network-assets" style={{ zIndex: 9998 }}>
      <Feeders />
      <DrawingTool />
      {network.map((asset) => (
        <Asset key={asset.id} asset={asset} color={setColor(asset)}></Asset>
      ))}
    </Pane>
  );
};

export default NetworkCreator;
