import lineIntersect from "@turf/line-intersect";
import distance from "@turf/distance";
import {
  lineString,
  Position,
  polygon,
  featureCollection,
  Feature,
  Polygon,
} from "@turf/helpers";
import GroundTypes from "../SPENGroundTypesMap.json";
import { GeoJsonProperties } from "geojson";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import flip from "@turf/flip";

export const getIntersectingLinesFromPolygon = (
  feature: Feature<Polygon>,
  coords: Position[]
) => {
  const flippedCoords = flip(featureCollection([lineString(coords)]))
    .features[0];
  const flippedFeature = flip(featureCollection([feature])).features[0];
  const poly = polygon(flippedFeature.geometry.coordinates);
  const line = lineString(flippedCoords.geometry.coordinates);
  const lineStartCoord = line.geometry.coordinates[0];
  const lineEndCoord = line.geometry.coordinates[1];
  const isLineStartInFeature = booleanPointInPolygon(lineStartCoord, poly);
  const isLineEndInFeature = booleanPointInPolygon(lineEndCoord, poly);
  const intersectingLines = lineIntersect(poly, line).features;
  const intersectingLineCoords: Position[] = intersectingLines.map((f) => [
    f.geometry.coordinates[1],
    f.geometry.coordinates[0],
  ]);
  isLineStartInFeature &&
    intersectingLineCoords.unshift([lineStartCoord[1], lineStartCoord[0]]);
  isLineEndInFeature &&
    intersectingLineCoords.push([lineEndCoord[1], lineEndCoord[0]]);
  return intersectingLineCoords;
};

export const getGroundType = (properties: GeoJsonProperties) => {
  const { Theme, DescriptiveGroup, DescriptiveTerm, Make } = properties as any;

  return GroundTypes.find(
    (f) =>
      f.theme === Theme &&
      f.descriptiveGroup === DescriptiveGroup &&
      f.descriptiveTerm === DescriptiveTerm &&
      f.make === Make
  )?.spenGroundType;
};

export const getLength = (feature: Position[]) => {
  if (feature.length < 2) return 0;
  return (
    distance(feature[1], feature[0], {
      units: "kilometers",
    }) * 1000
  );
};
