import { ButtonGroup, Button, Modal } from "react-bootstrap";

import styles from "./DialogModal.module.scss";

import { useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";
import { isEmpty } from "../../utils/isEmpty";

const DialogModal = ({
  affirmDialogAction,
  dismissDialogAction,
}: {
  affirmDialogAction: (props?: any[]) => void;
  dismissDialogAction: (props?: any[]) => void;
}) => {
  const app = useAppSelector(selectApp);

  const { affirmLabel, dismissLabel, messages, info, props } = app.dialog;

  const affirmAction = () => {
    affirmLabel && affirmDialogAction(props);
  };

  const dismissAction = () => {
    dismissLabel && dismissDialogAction(props);
  };

  return (
    <Modal show={!isEmpty(app.dialog)} onHide={() => { }} backdrop="static">
      {info && info?.length > 0 ? (
        <>
          <Modal.Body
            className="bg-warning text-light"
            style={{ fontSize: ".875rem", fontWeight: "normal" }}
          >
            {messages && messages.length > 1 ? (
              <div style={{ display: "block", width: "100%" }}>
                <ul>
                  {messages.map((message) => (
                    <li>{message.description}</li>
                  ))}
                </ul>
              </div>
            ) : (
              messages && (
                <div>{messages[0].description}<br /></div>
              )
            )}
            {info && info.length > 1 ? (
              <div>
                <br />
                <ul className={styles.extraInfo}>
                  {info.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
                <br />
                <br />
              </div>
            ) : (
              <div>
                <br />
                {info && info[0]}
                <br />
                <br />
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between p-2">
              <ButtonGroup>
                {affirmLabel && (
                  <Button variant="outline-light" size="sm" onClick={affirmAction} style={{ marginRight: 10}}>
                    {affirmLabel}
                  </Button>
                )}
                <Button variant="outline-light" size="sm" onClick={dismissAction}>
                  {dismissLabel || "Close"}
                </Button>
              </ButtonGroup>
            </div>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Body
            className="bg-warning text-light d-flex justify-content-between"
            style={{ fontSize: ".875rem", fontWeight: "normal" }}
          >
            {messages && messages.length > 1 ? (
              <ul>
                {messages.map((message) => (
                  <li>{message.description}</li>
                ))}
              </ul>
            ) : (
              messages && messages[0].description
            )}
            <ButtonGroup>
              {affirmLabel && (
                <Button variant="outline-light" size="sm" onClick={affirmAction} style={{ marginRight: 10}}>
                  {affirmLabel}
                </Button>
              )}
              <Button variant="outline-light" size="sm" onClick={dismissAction}>
                {dismissLabel || "Close"}
              </Button>
            </ButtonGroup>
          </Modal.Body>
        </>
      )
      }
    </Modal>
  );
};

export default DialogModal;
