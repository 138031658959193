import { LatLngObj } from "../../models/models";
import userGuidanceMessages from "./userGuidanceMessages.json";

import selectSiteBoundary from "../../img/1-select-site-boundary.gif";
import drawSiteBoundary from "../../img/1-draw-site-boundary.gif";
import selectMeterPoint from "../../img/2-select-meter-point.gif";
import placeMeterPoint from "../../img/2-place-meter-point.gif";
import selectLayPath from "../../img/3-select-lay-path.gif";
import drawLayPath from "../../img/3-draw-lay-path.gif";
import submit from "../../img/4-submit.gif";

let instruction = "";
let image = "";

export const siteMessages = (activeTool: string, vertices: LatLngObj[]) => {
  switch (true) {
    case !activeTool:
      instruction = userGuidanceMessages["1.1"];
      image = selectSiteBoundary;
      break;
    case activeTool === "site" && vertices.length < 1:
      instruction = userGuidanceMessages["1.2"];
      image = drawSiteBoundary;
      break;
    case activeTool === "site" && vertices.length < 3:
      instruction = userGuidanceMessages["1.3"];
      image = drawSiteBoundary;
      break;
    case activeTool === "site" && vertices.length > 2:
      instruction = userGuidanceMessages["1.4"];
      image = drawSiteBoundary;
      break;
    default:
      instruction = "";
  }
  return { instruction, image };
};

export const meterMessages = (activeTool: string) => {
  switch (true) {
    case !activeTool:
      instruction = userGuidanceMessages["2.1"];
      image = selectMeterPoint;
      break;
    case activeTool === "meter":
      instruction = userGuidanceMessages["2.2"];
      image = placeMeterPoint;
      break;
    default:
      instruction = "";
  }
  return { instruction, image };
};

export const cableMessages = (
  activeTool: string,
  vertices: LatLngObj[],
  networkHasCables: boolean,
  isFirstConnectedAssetCable: boolean
) => {
  switch (true) {
    case !activeTool:
      instruction = userGuidanceMessages["3.1"];
      image = selectLayPath;
      break;
    case activeTool === "cable" && vertices.length < 1 && !networkHasCables:
      instruction = userGuidanceMessages["3.2.1"];
      image = drawLayPath;
      break;
    case activeTool === "cable" && vertices.length < 1 && networkHasCables:
      instruction = userGuidanceMessages["3.2.2"];
      image = drawLayPath;
      break;
    case activeTool === "cable" &&
      vertices.length > 0 &&
      isFirstConnectedAssetCable:
      instruction = userGuidanceMessages["3.3.1"];
      image = drawLayPath;
      break;
    case activeTool === "cable" &&
      vertices.length > 0 &&
      !isFirstConnectedAssetCable &&
      !networkHasCables:
      instruction = userGuidanceMessages["3.3.2"];
      image = drawLayPath;
      break;
    case activeTool === "cable" &&
      vertices.length > 0 &&
      !isFirstConnectedAssetCable &&
      networkHasCables:
      instruction = userGuidanceMessages["3.3.3"];
      image = drawLayPath;
      break;
    default:
      instruction = "";
  }
  return { instruction, image };
};

export const submitMessages = () => {
  instruction = userGuidanceMessages["4.1"];
  return { instruction, image: submit };
};
