import { LatLngBounds } from "leaflet";
import axios from "axios";
import { FeatureCollection } from "geojson";

const getBounds = (bounds: LatLngBounds) => {
  return {
    southLat: bounds.getSouth(),
    westLng: bounds.getWest(),
    northLat: bounds.getNorth(),
    eastLng: bounds.getEast(),
  };
};

export const getGeoJsonWithBounds : (bounds: LatLngBounds, layer: string, abortController?: any) => Promise<FeatureCollection | null | undefined> = (bounds, layer, abortController = null) => {

  const bb = getBounds(bounds);

  const url = `${layer}&bbox=${bb.southLat},${bb.westLng},${bb.northLat},${bb.eastLng}`;

  return axios
    .get(url, {
      signal: abortController?.signal
    })
    .then((response) => {
      const contentType = response.headers["content-type"];
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.data as FeatureCollection;
      }
    })
    .catch((error) => {
      if (abortController?.signal.aborting || abortController?.signal.aborted) {
        return null;
      } else {
        console.log(error);
        if (error.response?.data?.messages?.length > 0) {
          error.response?.data?.messages?.map((msg: any) => console.log(msg));
        }
      }
    });
};
