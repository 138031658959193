import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useAppSelector } from "../../app/hooks";
import useNetwork from "../../hooks/useNetwork";
import { selectSteps } from "./stepsSlice";
import { selectNetworkCreator } from "../networkCreator/networkCreatorSlice";
import { selectApp } from "../../app/appSlice";
import Step from "./Step";
import StepToolBtn from "./StepToolBtn";
import IconButton from "../../components/iconBtn/IconButton";
import styles from "./Steps.module.css";
import { tabIndexes } from "../../app/tabIndexes";
import { Button } from "react-bootstrap";

const Steps = () => {
  const app = useAppSelector(selectApp);
  const { connectionMethod, userRole } = app.inputs;

  const networkCreator = useAppSelector(selectNetworkCreator);
  const steps = useAppSelector(selectSteps);
  const { network } = networkCreator;
  const { mode } = steps;
  const nodeRef = useRef(null);
  const { isNetworkConnected, hasGroundtypes } = useNetwork();

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={mode !== "closed"}
      timeout={150}
      classNames="steps"
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className={`${styles.steps} d-flex align-items-start flex-column bg-light`}
        aria-label="steps"
      >
        <Step
          stepNumber={1}
          name="Draw Site Boundary"
          cssClasses="w-100"
          overlay={false}
        >
          <StepToolBtn
            item={{ id: "", name: "site" }}
            tabIndex={tabIndexes.drawSiteBoundary}
            disabled={false}
          />
        </Step>
        <Step
          stepNumber={2}
          name="Place Site Access Point"
          cssClasses="w-100"
          overlay={!network.find((n) => n.type === "site")}
        >
          <StepToolBtn
            item={{ id: "", name: "meter" }}
            tabIndex={tabIndexes.placeSiteAccessPoint}
            disabled={!(network.find((n) => n.type === "site") && hasGroundtypes)} // wait for site AND groundtypes
            title={!hasGroundtypes ? "Waiting for data..." : ""}
          />
        </Step>
        {connectionMethod === "manual" && (
          <Step
            stepNumber={3}
            name="Draw Cable Route"
            cssClasses="w-100"
            overlay={!network.find((n) => n.name === "meter")}
          >
            <StepToolBtn
              item={{ id: "", name: "cable" }}
              tabIndex={tabIndexes.drawCableRoute}
              disabled={!network.find((n) => n.name === "meter")}
            />
          </Step>
        )}
        <Step
          stepNumber={connectionMethod === "manual" ? 4 : 3}
          name="Submit"
          cssClasses="w-100"
          overlay={!isNetworkConnected}
        >
        <IconButton
          color="primary"
          icon="paper-plane-solid"
          className={styles.submit}
          tabIndex={tabIndexes.submit}
          disabled={!isNetworkConnected}
        />
        </Step>
        {userRole == "external" && (
          <div className="position-relative w-100 mt-auto p-2">
            <Button
            variant="secondary"
            size="sm"
            block
            onClick={() => {
              window.parent.postMessage(
                {
                  restartClicked: true,
                },
                "*"
              );
            }}>
              Restart
            </Button>
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

export default Steps;
