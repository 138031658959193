import L from "leaflet";

export const refineSnapping = (map, e, closestAsset, threshold) => {
  if (closestAsset.distance < 24) {
    const isMarker = ["marker", "existingpole"].includes(
      closestAsset.asset.type
    );
    if (!isMarker) {
      return _checkPrioritySnapping(map, closestAsset);
    } else {
      return closestAsset.latlng;
    }
  } else {
    return { lat: e.latlng.lat, lng: e.latlng.lng };
  }
};

export const _handleSnapping = (map, latlng, assets) => {
  // get the closest layer, it's closest latlng, segment and the distance
  const closestAsset = _calcClosestAsset(map, latlng, assets);

  // if no layers found. Can happen when circle is the only visible layer on the map and the hidden snapping-border circle layer is also on the map
  if (Object.keys(closestAsset).length === 0) {
    return false;
  }

  const isMarker = closestAsset.asset.type === "marker";

  // find the final latlng that we want to snap to
  let snapLatLng;
  if (!isMarker) {
    snapLatLng = _checkPrioritySnapping(closestAsset);
  } else {
    snapLatLng = closestAsset.latlng;
  }

  return snapLatLng;
};

export const _calcClosestAsset = (map, latlng, assets) => {
  // the closest polygon to our dragged marker latlng
  let closestAssets = [];
  let closestAsset = {};

  // loop through the layers
  assets.forEach((asset, index) => {
    // find the closest latlng, segment and the distance of this layer to the dragged marker latlng
    const results = _calcLayerDistances(map, latlng, asset);

    // // show indicator lines, it's for debugging
    // this.debugIndicatorLines[index].setLatLngs([latlng, results.latlng]);

    // save the info if it doesn't exist or if the distance is smaller than the previous one
    if (
      closestAsset.distance === undefined ||
      results.distance <= closestAsset.distance
    ) {
      if (results.distance < closestAsset.distance) {
        closestAssets = [];
      }
      closestAsset = results;
      closestAsset.asset = asset;
      closestAssets.push(closestAsset);
    }
  });

  // return the closest layer and it's data
  // if there is no closest layer, return an empty object
  //   console.log(closestAssets);
  //   return closestAssets;
  return _getClosestAssetByPriority(closestAssets);
};
export const _calcLayerDistances = (map, latlng, asset) => {
  // is this a marker?
  const isMarker = ["marker", "existingpole"].includes(asset.type);

  // is it a polygon?
  const isPolygon =
    asset.type === "cable" ||
    asset.type === "site" ||
    asset.type === "new-domestic" ||
    asset.type === "new-non-domestic" ||
    asset.type === "new-mixed";

  // the point P which we want to snap (probpably the marker that is dragged)
  const P = latlng;

  // the coords of the layer
  //   const latlngs = isMarker
  //     ? L.latLng(asset.geometry[0], asset.geometry[1])
  //     : asset.geometry.map((latlng) => L.latLng([latlng[0], latlng[1]]));

  // the coords of the layer
  const latlngs = isMarker ? asset.markerGeometry : asset.polyGeometry;

  if (isMarker) {
    // return the info for the marker, no more calculations needed
    return {
      latlng: Object.assign({}, latlngs),
      distance: _getDistance(map, latlngs, P),
    };
  }

  // the closest segment (line between two points) of the layer
  let closestSegment;

  // the shortest distance from P to closestSegment
  let shortestDistance;

  // loop through the coords of the layer
  const loopThroughCoords = (coords) => {
    coords.forEach((coord, index) => {
      if (Array.isArray(coord)) {
        loopThroughCoords(coord);
        return;
      }

      // take this coord (A)...
      const A = coord;
      let nextIndex;

      // and the next coord (B) as points
      if (isPolygon) {
        nextIndex = index + 1 === coords.length ? 0 : index + 1;
      } else {
        nextIndex = index + 1 === coords.length ? undefined : index + 1;
      }

      const B = coords[nextIndex];

      if (B) {
        // calc the distance between P and AB-segment
        const distance = _getDistanceToSegment(map, P, A, B);

        // is the distance shorter than the previous one? Save it and the segment
        if (shortestDistance === undefined || distance < shortestDistance) {
          shortestDistance = distance;
          closestSegment = [A, B];
        }
      }
    });
  };

  loopThroughCoords(latlngs);

  // now, take the closest segment (closestSegment) and calc the closest point to P on it.
  const C = _getClosestPointOnSegment(
    map,
    latlng,
    closestSegment[0],
    closestSegment[1]
  );

  // return the latlng of that sucker
  return {
    latlng: Object.assign({}, C),
    segment: closestSegment,
    distance: shortestDistance,
  };
};

const _getClosestAssetByPriority = (assets) => {
  // sort the layers by creation, so it is snapping to the oldest layer from the same shape
  assets = assets.sort((a, b) => a.id - b.id);

  const shapes = [
    "Marker",
    "CircleMarker",
    "Circle",
    "Line",
    "Polygon",
    "Rectangle",
  ];
  //   const order = this._map.pm.globalOptions.snappingOrder || [];
  const order = [];

  let lastIndex = 0;
  const prioOrder = {};
  // merge user-preferred priority with default priority
  order.concat(shapes).forEach((shape) => {
    if (!prioOrder[shape]) {
      lastIndex += 1;
      prioOrder[shape] = lastIndex;
    }
  });

  // sort layers by priority
  assets.sort(prioritiseSort("instanceofShape", prioOrder));
  return assets.find((a) => a.asset.type === "existingpole") || assets[0] || {};
};
// we got the point we want to snap to (C), but we need to check if a coord of the polygon
// receives priority over C as the snapping point. Let's check this here
export const _checkPrioritySnapping = (map, closestAsset) => {
  // A and B are the points of the closest segment to P (the marker position we want to snap)
  const A = closestAsset.segment[0];
  const B = closestAsset.segment[1];

  // C is the point we would snap to on the segment.
  // The closest point on the closest segment of the closest polygon to P. That's right.
  const C = closestAsset.latlng;

  // distances from A to C and B to C to check which one is closer to C
  const distanceAC = _getDistance(map, A, C);
  const distanceBC = _getDistance(map, B, C);

  // closest latlng of A and B to C
  let closestVertexLatLng = distanceAC < distanceBC ? A : B;

  // distance between closestVertexLatLng and C
  let shortestDistance = distanceAC < distanceBC ? distanceAC : distanceBC;

  // snap to middle (M) of segment if option is enabled
  //if (this.options.snapMiddle) {
  // const M = calcMiddleLatLng(map, A, B);
  // const distanceMC = _getDistance(map, M, C);

  // if (distanceMC < distanceAC && distanceMC < distanceBC) {
  //   // M is the nearest vertex
  //   closestVertexLatLng = M;
  //   shortestDistance = distanceMC;
  // }
  //}

  // the distance that needs to be undercut to trigger priority
  const priorityDistance = 20;

  // the latlng we ultemately want to snap to
  let snapLatlng;

  // if C is closer to the closestVertexLatLng (A, B or M) than the snapDistance,
  // the closestVertexLatLng has priority over C as the snapping point.
  if (shortestDistance < priorityDistance) {
    snapLatlng = closestVertexLatLng;
  } else {
    snapLatlng = C;
  }

  // return the copy of snapping point
  return Object.assign({}, snapLatlng);
};

const _getClosestPointOnSegment = (map, latlng, latlngA, latlngB) => {
  let maxzoom = map.getMaxZoom();
  if (maxzoom === Infinity) {
    maxzoom = map.getZoom();
  }
  const P = map.project(latlng, maxzoom);
  const A = map.project(latlngA, maxzoom);
  const B = map.project(latlngB, maxzoom);
  const closest = L.LineUtil.closestPointOnSegment(P, A, B);
  return map.unproject(closest, maxzoom);
};

const _getDistanceToSegment = (map, latlng, latlngA, latlngB) => {
  const P = map.latLngToLayerPoint(latlng);
  const A = map.latLngToLayerPoint(latlngA);
  const B = map.latLngToLayerPoint(latlngB);
  return L.LineUtil.pointToSegmentDistance(P, A, B);
};

const _getDistance = (map, latlngA, latlngB) => {
  return map
    .latLngToLayerPoint(latlngA)
    .distanceTo(map.latLngToLayerPoint(latlngB));
};

// this function is used with the .sort(prioritiseSort(key, sortingOrder)) function of arrays
export function prioritiseSort(key, _sortingOrder, order = "asc") {
  /* the sorting order has all possible keys (lowercase) with the index and then it is sorted by the key on the object */

  if (!_sortingOrder || Object.keys(_sortingOrder).length === 0) {
    return (a, b) => a - b; // default sort method
  }

  // change the keys to lowercase
  const keys = Object.keys(_sortingOrder);
  let objKey;
  let n = keys.length;
  const sortingOrder = {};
  while (n--) {
    objKey = keys[n];
    sortingOrder[objKey.toLowerCase()] = _sortingOrder[objKey];
  }

  // function getShape(layer) {
  //   if (layer instanceof L.Marker) {
  //     return "Marker";
  //   } else if (layer instanceof L.Circle) {
  //     return "Circle";
  //   } else if (layer instanceof L.CircleMarker) {
  //     return "CircleMarker";
  //   } else if (layer instanceof L.Rectangle) {
  //     return "Rectangle";
  //   } else if (layer instanceof L.Polygon) {
  //     return "Polygon";
  //   } else if (layer instanceof L.Polyline) {
  //     return "Line";
  //   } else {
  //     return undefined;
  //   }
  // }

  return (a, b) => {
    let keyA;
    let keyB;
    if (key === "instanceofShape") {
      keyA = a.asset.type;
      keyB = b.asset.type;
      if (!keyA || !keyB) return 0;
    } else {
      /* eslint-disable-next-line no-prototype-builtins */
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      keyA = a[key].toLowerCase();
      keyB = b[key].toLowerCase();
    }

    const first =
      keyA in sortingOrder ? sortingOrder[keyA] : Number.MAX_SAFE_INTEGER;

    const second =
      keyB in sortingOrder ? sortingOrder[keyB] : Number.MAX_SAFE_INTEGER;

    let result = 0;
    if (first < second) result = -1;
    else if (first > second) result = 1;
    return order === "desc" ? result * -1 : result;
  };
}