import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import stepsReducer from "../features/steps/stepsSlice";
import appReducer from "./appSlice";
import networkCreatorReducer from "../features/networkCreator/networkCreatorSlice";
import existingNetworkReducer from "../features/existingNetwork/existingNetworkSlice";
import heatmapReducer from "../features/heatmap/heatmapSlice";
import undoable from "redux-undo";

const reducer = {
  app: appReducer,
  steps: stepsReducer,
  networkCreator: undoable(networkCreatorReducer),
  existingNetwork: existingNetworkReducer,
  heatmap: heatmapReducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
