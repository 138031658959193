import L, { LatLngTuple } from "leaflet";
import { CircleMarker, Polyline, useMap, useMapEvents, Popup, MapContainer } from "react-leaflet";
import { lineString } from "@turf/helpers";
import length from "@turf/length";
import { selectApp } from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ExistingNetworkAsset, LatLngObj } from "../../models/models";
import { getGeoJsonWithBounds } from "../../services/geoServerService";
import { mapCablePropertiesToJunction } from "../../utils/mapCablePropertiesToJunction";
import { convertFeatures } from "../../utils/networkUtils";
import { reverseCoords } from "../../utils/reverseCoords";
import {
  updateConductingSections,
  updateOverheadPoles,
  selectExistingNetwork,
  setSelectedCable,
  setSelectedCableWeight
} from "./existingNetworkSlice";
import { useState } from "react";
import { getCachedResponse, setCachedResponse } from "../../utils/responseCache";
import React from "react";

interface IConductors {
  name: string;
  url: string;
  secondUrl?: string;
}

let abortController = new AbortController();

const myRenderer = L.canvas({ tolerance: 2 });

const Conductors = ({ name, url, secondUrl }: IConductors) => {
  const map = useMap();  
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const app = useAppSelector(selectApp);
  const { totalkVA, singlePhaseConnections, threePhaseConnections, userRole } = app.inputs;
  const { conductingSections, overheadPoles, selectedCable, selectedCableWeight } = existingNetwork;
  const dispatch = useAppDispatch();

  const { submit } = app;

  const singlePhaseConnection = singlePhaseConnections === 1 && !threePhaseConnections;

  const [currentMapBounds, setCurrentMapBounds] = useState<L.LatLngBounds | undefined>(undefined);

  const filterConvertedFeaturesByLength = (
    convertedFeatures: ExistingNetworkAsset[]
  ) => {
    const arr: ExistingNetworkAsset[] = [];
    convertedFeatures.forEach((feature) => {
      const features = feature.polyGeometry?.map((geo) => [
        (geo as LatLngObj).lng,
        (geo as LatLngObj).lat,
      ]);
      if (features) {
        const line = lineString(features);
        if (length(line, { units: "miles" }) < 6) {
          arr.push(feature);
        }
      }
    });
    return arr;
  };

  const showPopupFields = process.env.REACT_APP_POPUP_FIELDS?.split(",");
  const showPopupOverheadPoleFields = process.env.REACT_APP_POPUP_OVERHEAD_POLE_FIELDS?.split(",");

  // AGW This function is a fix as we don't know where seasons are selected
  // const filterConvertedFeaturesBySeason = (
  //   convertedFeatures: ExistingNetworkAsset[]
  // ) => {
  //   const arr: ExistingNetworkAsset[] = [];
  //   convertedFeatures.forEach((feature) => {
  //     if ((feature.properties as any).Season === "summer") {
  //       arr.push(feature);
  //     }
  //   });
  //   return arr;
  // };

  // change geoserver generated id's to use properties.AcLineSegmentId or properties.JunctionId
  const reIdentity = (response: any) => {
    response.features.forEach((r: any) => {
      if (r.properties.AcLineSegmentId !== undefined) {
        r.id = "AcLineSegment." + r.properties.AcLineSegmentId;
      } else if (r.properties.JunctionId !== undefined) {
        r.id = "JunctionIsPole." + r.properties.JunctionId;
      }
    });
  }

  const updateFeatures = async () => {

    //console.log("Conductors updateFeatures");

    abortController.abort();
    abortController = new AbortController();

    //document.dispatchEvent(new Event("busyNetwork"));

    const bounds: any = map.getBounds();

    // check if we've already got this response in the cache
    let response: any = await getCachedResponse(bounds, url);
    if (response === null) {
      document.dispatchEvent(new Event("busyNetwork"));
      response = await getGeoJsonWithBounds(bounds, url, abortController);
      if (response === undefined) {
        document.dispatchEvent(new Event("notbusyNetwork"));
        return console.log("Failed to get json from ", url);
      } else if (response === null) {
        return; // console.log("null json from ", url);
      }
      reIdentity(response);
      await setCachedResponse(bounds, url, response);
    }
    //console.log(url, response);

    if (secondUrl) {
      let response2: any = await getCachedResponse(bounds, secondUrl);
      if (response2 === null) {
        response2 = await getGeoJsonWithBounds(bounds, secondUrl, abortController);
        if (response2 === undefined) {
          document.dispatchEvent(new Event("notbusyNetwork"));
          return console.log("Failed to get json from ", secondUrl);
        } else if (response2 === null) {
          return; // console.log("null json from ", url);
        }
        reIdentity(response2);
        await setCachedResponse(bounds, secondUrl, response2);
      }
      //console.log(secondUrl, response2);
      response.features = await mapCablePropertiesToJunction(map, response, response2);
    }

    const reversedFeatureCollection = reverseCoords(response);

    if (name === "conductingSections") {
      const convertedFeatures = convertFeatures(
        reversedFeatureCollection.features,
        totalkVA,
        singlePhaseConnection
      );

      const filteredConvertedFeatures = filterConvertedFeaturesByLength(convertedFeatures);
      //const filteredConvertedFeaturesBySeason = filterConvertedFeaturesBySeason(filteredConvertedFeatures);
      dispatch(updateConductingSections(filteredConvertedFeatures));
      //console.log("updateConductingSections called", filteredConvertedFeatures);
    }

    if (name === "overheadPoles") {
      const convertedFeatures = convertFeatures(
        reversedFeatureCollection.features,
        totalkVA,
        singlePhaseConnection
      );
      //const filteredConvertedFeaturesBySeason = filterConvertedFeaturesBySeason(convertedFeatures);
      dispatch(updateOverheadPoles(convertedFeatures));
      //console.log("updateOverheadPoles called", convertedFeatures);
    }

    document.dispatchEvent(new Event("notbusyNetwork"));
  };

  const ShowField = (field: string) => {
    return showPopupFields?.find((x) => x.startsWith(field));
  };

  const ShowOverheadPoleField = (field: string) => {
    return showPopupOverheadPoleFields?.find((x) => x.startsWith(field));
  };

  const DisplayField = (key: string, value: any) => {
    if (value === null || value === undefined || value === "")
      return "null";
    return value === false ? "false" : value === true ? "true" : DisplayFormatted(key, value, showPopupFields);
  };

  const DisplayOverheadPoleField = (key: string, value: any) => {
    if (value === null || value === undefined || value === "")
      return "null";
    return value === false ? "false" : value === true ? "true" : DisplayFormatted(key, value, showPopupOverheadPoleFields);
  };

  const DisplayFormatted = (key: string, value: any, fields: string[] | undefined) => {
    let formatting = fields?.find((x) => x.startsWith(key));
    if (formatting !== undefined) {
      let fmtArray = formatting?.split("|");
      if (fmtArray !== undefined) {
        if (fmtArray[1] === "decimal") {
          if (fmtArray[2] !== undefined && fmtArray[2] == "%") {
            if (fmtArray[3] !== undefined) {
              return (parseFloat(value) * parseFloat(fmtArray[3])).toFixed(3) + "%";
            }
            return parseFloat(value).toFixed(3) + "%";
          }
          if (fmtArray[2] !== undefined) {
            return parseFloat(value).toFixed(3) + fmtArray[2];
          }
          return parseFloat(value).toFixed(3);
        } else if (fmtArray[1] === "int") {
          if (fmtArray[2] !== undefined) {
            return value + fmtArray[2];
          }
          return value;
        }
      }
    }
    return value;
  }

  useMapEvents({
    async moveend() {
      if (conductingSections.length === 0) {
        //console.log("No conducting sections so resetting current map bounds");
        setCurrentMapBounds(undefined);
      } else {
        const newMapBounds = map.getBounds();
        if (currentMapBounds !== undefined && currentMapBounds.contains(newMapBounds)) {
          //console.log("already got conductors!");
          return;
        }
        setCurrentMapBounds(newMapBounds);
      }

      if (!submit) {
        //console.log("refresh conductors..");
        await updateFeatures();
      }
    },
  });

  const selectCable = (cable: any) => {
    //console.log("selectCable", cable._path._leaflet_id, cable);
    dispatch(setSelectedCable(cable));
    dispatch(setSelectedCableWeight(cable.options.weight));
    cable.setStyle({ weight: cable.options.weight + 3 });
  }

  const resetCable = () => {
    if (selectedCable !== undefined) {
      //console.log("resetCable", selectedCable._path._leaflet_id, selectedCableWeight);
      selectedCable?.setStyle({ weight: selectedCableWeight });
    } else {
      //console.log("resetCable", selectedCable);
    }
  }

  const handleClick = (e: any) => {
    if (userRole === "internal") {
      resetCable();
      selectCable(e.target);
      // console.table({
      //   ...{ id: feature.id },
      //   ...feature.properties,
      // });
    }
  }

  return (
    <>
      {name === "conductingSections" &&
        conductingSections.length > 0 &&
        conductingSections.map((feature: any) => (
          <Polyline
            key={feature.id}
            positions={feature.polyGeometry}
            color={feature.properties.color}
            weight={2}
            dashArray={feature.properties.dashArray}
            interactive={feature.properties && userRole === "internal" ? true : false}
            eventHandlers={{
              click: handleClick.bind(this),
              popupclose: resetCable
            }}
            renderer={userRole === "internal" ? myRenderer : undefined}
          >
            {feature.properties && userRole === "internal" && (
              <Popup className="leaflet-popup leaflet-conductor-popup" maxHeight={200} maxWidth={800} autoPan={false} keepInView={true} closeOnClick={true}>
                <table>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <b>Conducting Section</b>
                        <hr className="leaflet-popup-separator" />
                      </td>
                    </tr>
                    {Object.keys(feature.properties).map((key) => {
                      if (ShowField(key)) {
                        if (key === "reason") {
                          let reasonsArray = feature.properties[key] as string;
                          let reasons = reasonsArray.split("|");
                          const streetView = feature.polyGeometry != null && feature.polyGeometry.length > 0 ? feature.polyGeometry[0] : null;
                          const streetViewUrl = "https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=" + streetView?.lat + "," + streetView?.lng;
                          return (
                            <React.Fragment key={key}>
                              <tr key={key}>
                                <td colSpan={2}>
                                  <hr className="leaflet-popup-separator" />
                                  <b>Debugging info</b>
                                </td>
                              </tr>
                              {reasons.map((element: any) => (
                                <tr key={key + element}>
                                  <td colSpan={2}>{element}</td>
                                </tr>
                              ))}
                              {streetView && (
                                <tr key={key + "location"}>
                                  <td colSpan={2}>
                                    <hr className="leaflet-popup-separator" />
                                    <a onClick={(e) => navigator.clipboard.writeText(streetView?.lat + "," + streetView?.lng)}><img src="/images/copy.png" onClick={(e) => { e.currentTarget.title = "copied"; e.currentTarget.src = '/images/copied.png'; }} className="leaflet-popup-copy-icon" title="Copy location to clipboard" /></a>
                                    <a href={streetViewUrl} target="_blank"><img src="/images/streetview.png" className="leaflet-popup-streetview-icon" title="Street View" /></a>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <tr key={key}>
                              <td colSpan={2}>
                                {key} <b>{DisplayField(key, feature.properties[key])}</b>
                              </td>
                            </tr>
                          );
                        }
                      }
                    })}
                  </tbody>
                </table>
              </Popup>
            )}
          </Polyline>
        ))}
      {name === "overheadPoles" &&
        overheadPoles.length > 0 &&
        overheadPoles.map((feature: any) => (
          <CircleMarker
            key={feature.id}
            center={feature.markerGeometry}
            radius={4}
            color={feature.properties.color}
            fillColor={feature.properties.color}
            opacity={1}
            fillOpacity={1}
            interactive={feature.properties && userRole === "internal" ? true : false}
            eventHandlers={{
              click: handleClick.bind(this),
              popupclose: resetCable
            }}
          >
            {feature.properties && userRole === "internal" && (
              <Popup className="leaflet-popup leaflet-conductor-popup" maxHeight={200} maxWidth={800} autoPan={false} keepInView={false} closeOnClick={true}>
                <table>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <b>Overhead Pole</b>
                        <hr className="leaflet-popup-separator" />
                      </td>
                    </tr>
                    {Object.keys(feature.properties).slice(0, 30).map((key) => {
                      if (ShowOverheadPoleField(key)) {
                        if (key === "reason") {
                          let reasonsArray = feature.properties[key] as string;
                          let reasons = reasonsArray.split("|");
                          const streetView = feature.markerGeometry != null ? feature.markerGeometry : null;
                          const streetViewUrl = "https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=" + streetView?.lat + "," + streetView?.lng;
                          return (
                            <React.Fragment key={key}>
                              <tr key={key}>
                                <td colSpan={2}>
                                  <hr className="leaflet-popup-separator" />
                                  <b>Debugging info</b>
                                </td>
                              </tr>
                              {reasons.map((element: any) => (
                                <tr key={key + element}>
                                  <td colSpan={2}>{element}</td>
                                </tr>
                              ))}
                              {streetView && (
                                <tr key={key + "location"}>
                                  <td colSpan={2}>
                                    <hr className="leaflet-popup-separator" />
                                    <a onClick={(e) => navigator.clipboard.writeText(streetView?.lat + "," + streetView?.lng)}><img src="/images/copy.png" onClick={(e) => { e.currentTarget.title = "copied"; e.currentTarget.src = '/images/copied.png'; }} className="leaflet-popup-copy-icon" title="Copy location to clipboard" /></a>
                                    <a href={streetViewUrl} target="_blank"><img src="/images/streetview.png" className="leaflet-popup-streetview-icon" title="Street View" /></a>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <tr key={key}>
                              <td colSpan={2}>
                                {key} <b>{DisplayOverheadPoleField(key, feature.properties[key])}</b>
                              </td>
                            </tr>
                          );
                        }
                      }
                    })}
                  </tbody>
                </table>
              </Popup>
            )}
          </CircleMarker>
        ))}
    </>
  );
};

export default Conductors;
