import L from "leaflet";
import { GeoJSON } from "react-leaflet";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getGeoJsonWithBounds } from "../../services/geoServerService";
import {
  updatePostCodeStats,
  selectHeatmap,
} from "../heatmap/heatmapSlice";
import { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server"
import { Feature, FeatureCollection, Geometry } from "geojson";

const {
  REACT_APP_GEOSERVER: geoserver,
  REACT_APP_GEOSERVER_WORKSPACE: geoserverWorkspace,
  REACT_APP_HEATMAP_SECTOR_DECIMAL_PLACES: sectorDecimalPlacesString,
  REACT_APP_HEATMAP_REGION_DECIMAL_PLACES: regionDecimalPlacesString,
} = process.env;

const sectorDecimalPlaces = Number(sectorDecimalPlacesString);
const regionDecimalPlaces = Number(regionDecimalPlacesString);

const geoserverurl = (layer: string) => {
  return `${geoserver}/${geoserverWorkspace}/ows?service=WFS&version=1.1.0&request=GetFeature&typename=${geoserverWorkspace}:${layer}&outputFormat=application%2Fjson&srsname=EPSG:4326`;
};

const postcodeStatsUrl = geoserverurl("PostCodeSectorStatistics");

interface IPostCodeStats {
  bounds: L.LatLngBounds;  
}


let abortController = new AbortController();

const PostCodeStats = (props: IPostCodeStats) => {
  const heatmap = useAppSelector(selectHeatmap);
  const { postcodeStats, mode, percentageCalculation, bandColors, min, bandSize} = heatmap;


  const getColor: (feature: Feature<Geometry, any>) => string = (feature) => {
    const val = (mode === "conductors")
      ? (percentageCalculation === "sector")
        ? feature.properties.GreyConductorsPercentage
        : feature.properties.GreyConductorsAsPercentageOfDNOTotal
      : (percentageCalculation === "sector")
      ? feature.properties.GreyTransformersPercentage
      : feature.properties.GreyTransformersAsPercentageOfDNOTotal;

    const band = Math.min(Math.floor((val - (min ?? 0)) / (bandSize ?? 0)), (bandColors.length) - 1);

    return band >= 0 ? bandColors[band] : "";
  };

  const getOpacity: (feature: Feature<Geometry, any>) => number = (feature) => {
    const val = (mode === "conductors")
      ? (percentageCalculation === "sector")
        ? feature.properties.GreyConductorsPercentage
        : feature.properties.GreyConductorsAsPercentageOfDNOTotal
      : (percentageCalculation === "sector")
      ? feature.properties.GreyTransformersPercentage
      : feature.properties.GreyTransformersAsPercentageOfDNOTotal;

    const band = Math.min(Math.floor((val - (min ?? 0)) / (bandSize ?? 0)), (bandColors.length) - 1);

    return band >= 0 ? 0.8 : 0;
  };

  const dispatch = useAppDispatch();

  const showPopupFields = process.env.REACT_APP_POPUP_POSTCODESTATS_FIELDS?.split(",");


  const updateFeatures = async () => {

    abortController.abort();
    abortController = new AbortController();

    //document.dispatchEvent(new Event("busyNetwork"));

    let response : FeatureCollection | null | undefined = await getGeoJsonWithBounds(props.bounds, postcodeStatsUrl, abortController);
    if (response === undefined) {
      //document.dispatchEvent(new Event("notbusyNetwork"));
      return console.log("Failed to get json from ", postcodeStatsUrl);
    } else if (response === null) {
      return; // console.log("null json from ", url);
    }

    dispatch(updatePostCodeStats(response));

    //document.dispatchEvent(new Event("notbusyNetwork"));    
  };

  const ShowField = (field: string) => {
    return showPopupFields?.find((x) => x.startsWith(field));
  };

  const DisplayField = (key: string, value: any) => {
    if (value === null || value === undefined || value === "")
      return "null";
    return value === false ? "false" : value === true ? "true" : DisplayFormatted(key, value, showPopupFields);
  };

  const DisplayFormatted = (key: string, value: any, fields: string[] | undefined) => {
    let formatting = fields?.find((x) => x.startsWith(key));
    if (formatting !== undefined) {
      let fmtArray = formatting?.split("|");
      if (fmtArray !== undefined) {
        if (fmtArray[1] === "decimal") {
          if (fmtArray[2] !== undefined && fmtArray[2] === "%") {
            if (fmtArray[3] !== undefined) {
              return (parseFloat(value) * parseFloat(fmtArray[3])).toFixed(DecimalPlaces(key)) + "%";
            }
            return parseFloat(value).toFixed(DecimalPlaces(key)) + "%";
          }
          if (fmtArray[2] !== undefined) {
            return parseFloat(value).toFixed(DecimalPlaces(key)) + fmtArray[2];
          }
          return parseFloat(value).toFixed(DecimalPlaces(key));
        } else if (fmtArray[1] === "int") {
          if (fmtArray[2] !== undefined) {
            return value + fmtArray[2];
          }
          return value;
        }
      }
    }
    return value;
  }

  const DecimalPlaces = (key: string) => {
    switch (key)
    {
      case "GreyConductorsPercentage": 
      case "GreyTransformersPercentage": return sectorDecimalPlaces;
      case "GreyConductorsAsPercentageOfDNOTotal": 
      case "GreyTransformersAsPercentageOfDNOTotal": return regionDecimalPlaces;
      default: return 3;
    }
  }

  const DisplayTitle = (key: string) => {
    switch (key){
      case "Text": return "Postcode sector";
      case "GreyConductorsCount": return "Number of grey conductors";
      case "GreyConductorsPercentage": return "% grey conductors within postcode sector";
      case "GreyConductorsAsPercentageOfDNOTotal": return "% grey conductors across entire NI region";
      case "GreyTransformersCount": return "Number of grey transformers";
      case "GreyTransformersPercentage": return "% grey transformers within postcode sector";
      case "GreyTransformersAsPercentageOfDNOTotal": return "% grey transformers across entire NI region";
      default: return key;
    }
  }

  useEffect(() => {
    updateFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPopupContent = (feature: any) => <table>
  <tbody>
    <tr>
      <td colSpan={2}>
        <b>Statistics</b>
        <hr className="leaflet-popup-separator" />
      </td>
    </tr>
    {Object.keys(feature.properties).map((key) => {
      if (ShowField(key)) {
        return (
          <tr key={key}>
            <td colSpan={2}>
              {DisplayTitle(key)} <b>{DisplayField(key, feature.properties[key])}</b>
            </td>
          </tr>
        );
      }

      return null;
    })}
  </tbody>
</table>


  return (
    <>
      {postcodeStats !== undefined && (
        <GeoJSON
          key={`${mode}_${percentageCalculation}`}
          data={postcodeStats} 
          style={(feature) => { return {
            color: "black",
            fillColor: (feature !== undefined) ? getColor(feature) : "red",
            fill: true,
            weight: 1,
            fillOpacity: (feature !== undefined) ? getOpacity(feature) : 1,
          }}}
          eventHandlers={{
            popupopen: (e) => {
              e.popup.options.autoPan = false;
              e.popup.update();
            },
            popupclose: (e) => {
              e.popup.options.autoPan = true;
              e.popup.update();
            }
          }}
          onEachFeature={(feature, layer) => {            
            var LPopup = new L.Popup({
              className:"leaflet-popup leaflet-conductor-popup",
              maxHeight:200,
              maxWidth:800,
              autoPan:true,
              keepInView:true,
              closeOnClick:true,
              content: renderToStaticMarkup(getPopupContent(feature))
            }, layer);

            layer.bindPopup(LPopup);

          }}
        />
      )}      
    </>
  )

  
};

export default PostCodeStats;
