import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Feature, MultiLineString, MultiPolygon } from "geojson";
import type { RootState } from "../../app/store";
import { NetworkAsset, LatLngObj, FeatureData } from "../../models/models";

export interface NetworkCreatorState {
  network: NetworkAsset[];
  activeTool?: string;
  vertices: LatLngObj[];
  firstConnectedAsset?: NetworkAsset;
  currentSegments: FeatureData[];
  intersectingSegments: { id: string; data: FeatureData[] }[];
  siteIntersectingPolygons: { id: string; data: FeatureData[] }[];
}

const initialState: NetworkCreatorState = {
  network: [],
  activeTool: undefined,
  vertices: [],
  firstConnectedAsset: undefined,
  currentSegments: [],
  intersectingSegments: [],
  siteIntersectingPolygons: [],
};

const filterPremises = (
  activeTool: string | undefined,
  state: NetworkCreatorState
) => {
  if (activeTool !== "meter") {
    state.network = state.network.filter(
      (asset) => asset.type !== "new-domestic" || asset.markerGeometry
    );
  }
};

export const networkCreatorSlice = createSlice({
  name: "networkCreator",
  initialState,
  reducers: {
    setActiveTool: (
      state,
      action: PayloadAction<{
        activeTool?: string;
      }>
    ) => {
      state.vertices = [];
      filterPremises(action.payload.activeTool, state);
      state.activeTool = action.payload.activeTool;
    },
    addToNetwork: (
      state,
      action: PayloadAction<{
        assets: NetworkAsset[];
        _activeTool?: string;
      }>
    ) => {
      state.vertices = [];
      state.activeTool = action.payload._activeTool;
      state.network = [...state.network, ...action.payload.assets];
    },
    removeFromNetwork: (state, action: PayloadAction<any>) => {
      const { property, value } = action.payload;
      if (property === "name") {
        state.network = state.network.filter((n) => n.type === "site");
      }
      if (property === "type") {
        const filteredNetwork = state.network.filter(
          (n) => n.type !== value && n.name !== "joint"
        );
        const updatedNetwork = filteredNetwork.map((asset) => ({
          ...asset,
          firstConnectedAsset: undefined,
          secondConnectedAsset: undefined,
          isConnected: false,
        }));
        state.network = updatedNetwork;
      }
      state.activeTool = undefined;
      state.currentSegments = [];
      state.intersectingSegments = [];
      state.firstConnectedAsset = undefined;
    },
    replaceNetwork: (state, action: PayloadAction<NetworkCreatorState>) => {
      state.network = action.payload.network;
      state.activeTool = action.payload.activeTool || undefined;
      state.vertices = action.payload.vertices;
      state.intersectingSegments = action.payload.intersectingSegments;
    },
    replaceNetworkAssets: (
      state,
      action: PayloadAction<NetworkAsset[] | undefined>
    ) => {
      if (!action.payload) return;
      const assetIds = action.payload.map((p) => p.id);
      const filteredNetwork = state.network.filter(
        (f) => !assetIds.includes(f.id)
      );
      state.network = [...filteredNetwork, ...action.payload];
    },
    updateNetworkAsset: (
      state,
      action: PayloadAction<{
        id: string;
        geometry?: LatLngObj;
      }>
    ) => {
      const obj = state.network.find(
        (f) => f.id === action.payload.id
      ) as NetworkAsset;
      obj.markerGeometry = action.payload.geometry;
      state.activeTool = undefined;
    },
    addVertex: (state, action: PayloadAction<LatLngObj | undefined>) => {
      if (!action.payload) return;
      state.vertices.push(action.payload);
    },
    setFirstConnectedAsset: (
      state,
      action: PayloadAction<NetworkAsset | undefined>
    ) => {
      if (!action.payload) return;
      state.firstConnectedAsset = action.payload;
    },
    replaceMarker: (
      state,
      action: PayloadAction<{
        marker: NetworkAsset;
        activeTool?: string;
      }>
    ) => {
      const marker = state.network.find(
        (n) => n.id === action.payload.marker.id
      );
      if (marker) marker.name = action.payload.activeTool;
    },
    clearNetwork: (state) => {
      state.network = [];
      state.activeTool = undefined;
      state.vertices = [];
      state.firstConnectedAsset = undefined;
      state.currentSegments = [];
      state.intersectingSegments = [];
      state.siteIntersectingPolygons = [];
    },
    addCurrentSegments: (
      state,
      action: PayloadAction<FeatureData[] | undefined>
    ) => {
      if (!action.payload) return;
      state.currentSegments = [...state.currentSegments, ...action.payload];
      //console.log("state.currentSegments=", state.currentSegments);
    },
    addIntersectingSegments: (state, action: PayloadAction<NetworkAsset[]>) => {

      //console.log("addIntersectingSegments", state, action);

      const intersectingSegment = {
        id: action.payload[0].id,
        data: [...state.currentSegments],
      };

      //console.log("intersectingSegment", intersectingSegment);

      // Segment made from 'currentSegments' and added to array
      state.intersectingSegments = [
        ...state.intersectingSegments,
        intersectingSegment,
      ];

      //console.log("state.intersectingSegments=", state.intersectingSegments);

      // remove blank intersecting segment
      //if (state.intersectingSegments[0].data.length === 0) {
      //  state.intersectingSegments.shift();
      //  //console.log("state.intersectingSegments now =", state.intersectingSegments);
      //}

      state.currentSegments = [];
    },
    addSiteIntersectingPolygons: (
      state,
      action: PayloadAction<{ siteId: string; features: FeatureData[] }>
    ) => {
      const intersectingPolygon = {
        id: action.payload.siteId,
        data: action.payload.features,
      };

      state.siteIntersectingPolygons = [
        ...state.siteIntersectingPolygons,
        intersectingPolygon,
      ];
      //console.log("state.siteIntersectingPolygons=", state.siteIntersectingPolygons);
    },
    completeAsset: (
      state,
      action: PayloadAction<{
        connectedAssets?: NetworkAsset[];
        assets: NetworkAsset[];
        _activeTool?: string;
        firstConnectedAsset?: NetworkAsset;
        features: FeatureData[];
      }>
    ) => {
      //console.log("completeAsset", state, action);
      const {
        connectedAssets,
        assets,
        _activeTool,
        firstConnectedAsset,
        features,
      } = action.payload;
      const {
        replaceNetworkAssets,
        addToNetwork,
        setFirstConnectedAsset,
        addIntersectingSegments,
        addSiteIntersectingPolygons,
      } = networkCreatorSlice.caseReducers;

      replaceNetworkAssets(state, {
        payload: connectedAssets,
        type: "",
      });
      addToNetwork(state, {
        payload: { assets, _activeTool },
        type: "",
      });
      setFirstConnectedAsset(state, {
        payload: firstConnectedAsset,
        type: "",
      });
      addIntersectingSegments(state, {
        payload: assets,
        type: "",
      });
      addSiteIntersectingPolygons(state, {
        payload: { siteId: assets[0].id, features },
        type: "",
      });
    },
    completeAsset2: (
      state,
      action: PayloadAction<{
        connectedAssets?: NetworkAsset[];
        assets: NetworkAsset[];
        _activeTool?: string;
        features?: FeatureData[];
      }>
    ) => {
      const { connectedAssets, assets, _activeTool, features } = action.payload;
      const {
        replaceNetworkAssets,
        addToNetwork,
        // setFirstConnectedAsset,
        addCurrentSegments,
        addIntersectingSegments,
      } = networkCreatorSlice.caseReducers;

      replaceNetworkAssets(state, {
        payload: connectedAssets,
        type: "",
      });
      addToNetwork(state, {
        payload: { assets, _activeTool },
        type: "",
      });
      // setFirstConnectedAsset(state, {
      //   payload: firstConnectedAsset,
      //   type: "",
      // });
      addCurrentSegments(state, {
        payload: features,
        type: "",
      });
      addIntersectingSegments(state, {
        payload: assets,
        type: "",
      });
    },
    completeSegment: (
      state,
      action: PayloadAction<{
        snap?: LatLngObj;
        features?: FeatureData[];
        firstConnectedAsset?: NetworkAsset;
      }>
    ) => {
      const { snap, features, firstConnectedAsset } = action.payload;
      const { addVertex, addCurrentSegments, setFirstConnectedAsset } =
        networkCreatorSlice.caseReducers;
      addVertex(state, {
        payload: snap,
        type: "",
      });
      addCurrentSegments(state, {
        payload: features,
        type: "",
      });
      setFirstConnectedAsset(state, {
        payload: firstConnectedAsset,
        type: "",
      });
    },
    updateSiteIntersectingPolygons: (
      state,
      action: PayloadAction<{ siteId: string; features: FeatureData[] }>
    ) => {
      //console.log("updateSiteIntersectingPolygons");
      let found = state.siteIntersectingPolygons.find((f) => f.id == action.payload.siteId);
      if (found) {
        //console.log("Found site.id", action.payload.siteId, "with existing data '", found.data + "'");
        if (found.data.length === 0) {
          //console.log("New data is '", action.payload.features, "'");
          found.data = action.payload.features;
          //console.log("Updated existing data with", found.data);
        } else {
          //console.log("Already has data so not changing feature data");
        }
      } else {
        //console.log("Unable to find site.id", action.payload.siteId, " so no feature data added!");
      }
      //console.log("~updateSiteIntersectingPolygons");
    },
    updateAsset: (
      state,
      action: PayloadAction<{
        siteId: string;
        features: FeatureData[];
      }>
    ) => {
      //console.log("updateAsset", state, action);
      const {
        siteId,
        features,
      } = action.payload;
      const {
        updateSiteIntersectingPolygons,
      } = networkCreatorSlice.caseReducers;

      updateSiteIntersectingPolygons(state, {
        payload: { siteId, features },
        type: "",
      });
    },
  },
});

export const {
  setActiveTool,
  addToNetwork,
  removeFromNetwork,
  replaceNetwork,
  updateNetworkAsset,
  replaceNetworkAssets,
  addVertex,
  setFirstConnectedAsset,
  replaceMarker,
  clearNetwork,
  addCurrentSegments,
  addIntersectingSegments,
  completeAsset,
  completeAsset2,
  completeSegment,
  updateAsset
} = networkCreatorSlice.actions;

export const selectNetworkCreator = (state: RootState) =>
  state.networkCreator.present;

export const hasPast = (state: RootState) =>
  state.networkCreator.past.length > 0;

export const getPast = (state: RootState) => state.networkCreator.past;

export const hasFuture = (state: RootState) =>
  state.networkCreator.future.length > 0;

export default networkCreatorSlice.reducer;
