import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import Legend from "../legend/Legend";
import UserGuidance from "../userGuidance/UserGuidance";
import { tabIndexes } from "../../app/tabIndexes";
import Options from "../options/Options";
import { useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";
import "./Console.scss";

export enum ConsoleOptions
{
  "user-guidance" = 1,
  legend = 2,
  options = 4
}


const showOptions = process.env.REACT_APP_SHOW_OPTIONS;

export interface IConsoleProps {
  availableOptions?: ConsoleOptions;
  defaultOption?: ConsoleOptions;
  userGuidance?: React.ReactNode;
  legend?: React.ReactNode;
  options?: React.ReactNode;
}

const Console = (props: IConsoleProps) => { 
  const [consoleMode, setConsoleMode] =
    useState<ConsoleOptions | undefined>(props.defaultOption ?? ConsoleOptions["user-guidance"]);

  const app = useAppSelector(selectApp);
  const { userRole } = app.inputs;

  const defaultAvailableOptions = ConsoleOptions["user-guidance"] | ConsoleOptions["legend"] | ConsoleOptions["options"];
  
  const handleChange = (value: ConsoleOptions) => {
    setConsoleMode((value !== consoleMode) ? value : undefined);
  };
  

  return (
    <div className="d-flex justify-content-between">
      <div className="position-relative">
        {consoleMode === ConsoleOptions["user-guidance"] && (props.userGuidance ?? <UserGuidance />)}
        {consoleMode === ConsoleOptions["legend"] && (props.legend ?? <Legend />)}
        {consoleMode === ConsoleOptions["options"] && (props.options ?? <Options />)}
      </div>
      <div className="mb-2 ml-2">
        <ButtonGroup className="console-group" vertical>
          {(((props.availableOptions ?? defaultAvailableOptions) & ConsoleOptions["user-guidance"]) === ConsoleOptions["user-guidance"]) && (
            <Button
              id="1"
              variant="light"
              value="user-guidance"
              className={consoleMode === ConsoleOptions["user-guidance"] ? "active" : ""}
              onClick={() => handleChange(ConsoleOptions["user-guidance"])}
              tabIndex={tabIndexes.userGuidance}
            >
              <i className="icon-question-circle-solid"></i>
            </Button>
          )}
          {(((props.availableOptions ?? defaultAvailableOptions) & ConsoleOptions["legend"]) === ConsoleOptions["legend"]) && (
            <Button
              id="1"
              variant="light"
              value="legend"
              className={consoleMode === ConsoleOptions["legend"] ? "active" : ""}
              onClick={() => handleChange(ConsoleOptions["legend"])}
              tabIndex={tabIndexes.legend}
            >
            <i className="icon-map-solid"></i>
            </Button>
          )}
          {(((props.availableOptions ?? defaultAvailableOptions) & ConsoleOptions["options"]) === ConsoleOptions["options"])
            && showOptions === "true"
            && userRole === "internal" && (
            <Button
              id="1"
              variant="light"
              value="options"
              className={consoleMode === ConsoleOptions["options"] ? "active" : ""}
              onClick={() => handleChange(ConsoleOptions["options"])}
              tabIndex={tabIndexes.options}
            >
              <i className="icon-cog-solid"></i>
            </Button>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Console;
