import L, { LatLng } from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectApp, setScreenshotMode, setSubmit, setShowGroundTypes, updateDialog } from "../../app/appSlice";
import { selectNetworkCreator } from "../../features/networkCreator/networkCreatorSlice";
import { selectExistingNetwork } from "../../features/existingNetwork/existingNetworkSlice";
import { LatLngObj, NetworkAsset } from "../../models/models";
import { getPackage } from "../../services/calculateService";
import "leaflet-simple-map-screenshoter";
import { completeNetworkPolygons } from "../../utils/networkUtils";
import { info } from "console";

function RequestBuilder() {
  const map = useMap();
  const simpleMapScreenshoter = L.simpleMapScreenshoter({
    hidden: true, // hide screen btn on map
  }).addTo(map);

  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const [showSpinner, setShowSpinner] = useState(false);

  const { submit, inputs } = app;
  const { network, intersectingSegments, siteIntersectingPolygons } =
    networkCreator;
  const { conductingSections, overheadPoles } = existingNetwork;

  useEffect(() => {
    submit && requestHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const getExistingNetworkAsset = () => {
    const cable = network.find((asset) => asset.type === "cable");
    const firstConnectedAsset = cable?.firstConnectedAsset;
    const secondConnectedAsset = cable?.secondConnectedAsset;

    const asset =
      conductingSections.find((c) =>
        [firstConnectedAsset, secondConnectedAsset].includes(c.id)
      ) ||
      overheadPoles.find((c) =>
        [firstConnectedAsset, secondConnectedAsset].includes(c.id)
      );
    // if existingpole, find the connected conducting section for the Network Translator
    if (asset && asset.type === "existingpole") {
      return conductingSections.find(
        (c) =>
          (c as any).properties.EndAssetId ===
          (asset as any).properties.JunctionId
      );
    } else {
      return asset;
    }
  };

  const requestHandler = async () => {
    dispatch(setSubmit(false));
    dispatch(setShowGroundTypes(false));
    dispatch(setScreenshotMode(true));

    const savedMapCoords = map.getBounds();

    const screen = await takeScreenshot();

    // stop layers call
    document.dispatchEvent(new Event("notbusyLayers"));

    setShowSpinner(true);

    let messageObj = {
      inputs: {
        quotationRef: "",
        quotationDateTime: "",
        connectionMethod: "manual",
        singlePhaseConnections: 0,
        threePhaseConnections: 0,
        connections: 0,
        multiSwitchConnections: [],
        searchType: "Postcode",
        searchValue: "bs20tb",
        isNewStudy: true,
        isSiteExcavationCostIncluded: false,
        totalkVA: 0,
        userRole: "external",
        buildingOverride: false
      },
      billOfMaterials: {
        screenshot: screen,
        currency: null,
        items: [],
        priceBookName: "",
        totalCost: null,
      },
    };

    let completedNetwork: NetworkAsset[] = completeNetworkPolygons(network);
    const existingNetworkAsset = getExistingNetworkAsset();
    if (existingNetworkAsset) {
      completedNetwork = [...completedNetwork, existingNetworkAsset];
    }

    const study = {
      name: "NIEN",
      network: completedNetwork,
      inputs,
      intersectingSegments,
      siteIntersectingPolygons,
        screenshot: screen
    };

    const response = await getPackage(study);

    setShowSpinner(false);
    dispatch(setScreenshotMode(false));

    if (response !== undefined && response.inputs !== undefined) {
      //console.log("Reponse is '" + response + "'");
      messageObj.inputs = {
        ...messageObj.inputs,
        ...response.inputs,
      };
      messageObj.billOfMaterials = {
        ...messageObj.billOfMaterials,
        ...response.billOfMaterials,
      };
      window.parent.postMessage(
        {
          message: JSON.stringify(messageObj, null, "\t"),
        },
        "*"
      );
    } else {
      map.fitBounds(savedMapCoords, { animate: false });
      if (response !== undefined) {
        dispatch(
          updateDialog({
            type: "warning",
            className: "warning",
            dismissLabel: "OK",
            messages: [
              {
                description:
                  "Sorry, we were unable to calculate the cost you requested. Please contact us on the number below, stating the following Correlation Id.",
              }
            ],
            info: [`Correlation Id: ${response}`]
          })
        );
      } else {
        dispatch(
          updateDialog({
            type: "warning",
            className: "warning",
            dismissLabel: "OK",
            messages: [
              {
                description:
                  "Sorry, we were unable to calculate the cost you requested. Please contact us on the number below.",
              }
            ]
          })
        );
      }
    }
  };

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const takeScreenshot = async () => {

    const latlngs: LatLng[] = [];
    network
      .filter((f) => f.polyGeometry)
      .forEach((n) => {
        (n.polyGeometry as LatLngObj[]).forEach((n) => {
          latlngs.push(L.latLng(n.lat, n.lng));
        });
      });

    map && map.fitBounds(L.latLngBounds(latlngs), { animate: false });
    let screenshot;
    const format: "image" | "blob" | "canvas" = "image";
    const overridedPluginOptions = {
      mimeType: "image/png",
    };

    // Instead of a 2 second pause (with a catch for 2 seconds overall), add an event listener to capture load event
    var loaded = false;
    map.eachLayer(async function (layer) {
      if(layer.options.attribution !== null) {
        //console.log("adding layer eventlistener");
        layer.addOneTimeEventListener("load", () => { loaded = true });
      }
    });
    let count = 0;
    while(!loaded && ++count <= 20)
    {
      await timeout(200);
    }
    // add another extra wait for safety
    await timeout(1000);

    await simpleMapScreenshoter
      .takeScreen(format, overridedPluginOptions)
      .then((image) => {
        screenshot = image;
      })
      .catch((e) => {
        console.log(e.toString());
      });

    return screenshot;
  };
  return (
    <>
      {showSpinner && (
        <>
          <div className="fade modal-backdrop show"></div>
          <div
            className="modal d-flex flex-column align-items-center justify-content-center center-all text-light"
            style={{ fontSize: "2rem" }}
          >
            <i className="icon-spinner-solid spinner"></i>
            <h4>Loading</h4>
          </div>
        </>
      )}
    </>
  );
}

export default RequestBuilder;
