export enum tabIndexes {
    expandCollapseSidebar = 100,
    undo = 200,
    redo = 300,
    drawSiteBoundary = 400,
    placeSiteAccessPoint = 500,
    drawCableRoute = 600,
    submit = 700,
    userGuidance = 800,
    legend = 900,
    options = 950,
    zoomIn = 1000,
    zoomOut = 1100,
};
