import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import booleanIntersects from "@turf/boolean-intersects";
import flatten from "@turf/flatten";
import flip from "@turf/flip";
import {
  featureCollection,
  lineString,
  point,
  polygon,
  Properties,
} from "@turf/helpers";
import proj4 from "proj4";
import {
  Feature,
  LineString,
  MultiLineString,
  Polygon,
  Position,
} from "geojson";
import { LatLng, LatLngBounds } from "leaflet";
import { FeatureCollection, GeoJsonProperties, MultiPolygon } from "geojson";

import { getGeoJsonWithBounds } from "../services/geoServerService";
import lineIntersect from "@turf/line-intersect";

const addTopographyType = (obj: any, value: string) => {
  //console.log(value, obj.numberReturned);
  return obj.features.map((f: any) => ({
    type: f.type,
    geometry: { ...f.geometry },
    properties: { ...f.properties, TOPOGRAPHYTYPE: value },
  }));
};

const {
  REACT_APP_GEOSERVER: geoserver,
  REACT_APP_GEOSERVER_WORKSPACE: geoserverWorkspace,
} = process.env;

const url = (layer: string) => {
  return `${geoserver}/${geoserverWorkspace}/ows?service=WFS&version=1.1.0&request=GetFeature&typename=${geoserverWorkspace}:${layer}&outputFormat=application%2Fjson&srsname=EPSG:4326`;
};

export const getGroundTypes = async (bounds: LatLngBounds) => {

  //console.log("getting ground types...");

  let features: any[] = [];

  const promiseAwait = new Promise<void>(async (resolve, reject) => {

    // MultiPolygons
    const promiseAwaitGroundCover = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("GroundCover"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "ground_cover"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    const promiseAwaitWater = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("Water"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "water_course_polygon"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    const promiseAwaitTransport = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("Transport"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "transport_casing"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    const promiseAwaitLandParcels = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("LandParcels"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "land_parcel"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    const promiseAwaitBuildings = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("Buildings"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "building"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    // MultiLineStrings
    const promiseAwaitRoads = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("Roads"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "road_link"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    const promiseAwaitStructures = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("Structures"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "structure_polygon"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    // Points
    const promiseAwaitLabels = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("Labels"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "label"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    const promiseAwaitRoadJunctions = new Promise<void>(async (resolve, reject) => {
      const response = await getGeoJsonWithBounds(bounds, url("RoadJunctions"));
      if (response !== undefined && response != null) {
        addTopographyType(
          flip(response as any),
          "road_junctions"
        ).map((m: any) => features.push(m));
      }
      resolve();
    });

    await promiseAwaitGroundCover;
    await promiseAwaitWater;
    await promiseAwaitTransport;
    await promiseAwaitLandParcels;
    await promiseAwaitBuildings;
    await promiseAwaitRoads;
    await promiseAwaitStructures;
    await promiseAwaitLabels;
    await promiseAwaitRoadJunctions;

    resolve();
  });

  const result = await promiseAwait;

  //console.log("got ground types now", features.length, features);

  // filter MultiLineString and Point data out until UI can cope with it
  const featuresMinusLineStringsAndPoints = features.filter(
    (n) =>
      n.geometry.type === "MultiPolygon" ||
      n.geometry.type === "MultiLineString"
  );

  const geoJson: FeatureCollection<MultiPolygon | MultiLineString> = {
    type: "FeatureCollection",
    features: featuresMinusLineStringsAndPoints as Feature<
      MultiPolygon | MultiLineString,
      GeoJsonProperties
    >[],
  };

  return geoJson;
};

// export const getGroundTypes = async (bounds: LatLngBounds) => {
//   const features = [
//     // MultiPolygons
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("GroundCover"))),
//       "ground_cover"
//     ),
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("Water"))),
//       "water_course_polygon"
//     ),
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("Transport"))),
//       "transport_casing"
//     ),
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("LandParcels"))),
//       "land_parcel"
//     ),
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("Buildings"))),
//       "building"
//     ),

//     // MultiLineStrings
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("Roads"))),
//       "road_link"
//     ),
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("Structures"))),
//       "structure_polygon"
//     ),

//     // Points
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("Labels"))),
//       "label"
//     ),
//     ...addTopographyType(
//       flip(await getGeoJsonWithBounds(bounds, url("RoadJunctions"))),
//       "road_junctions"
//     ),
//   ];

//   // filter MultiLineString and Point data out until UI can cope with it
//   const featuresMinusLineStringsAndPoints = features.filter(
//     (n) =>
//       n.geometry.type === "MultiPolygon" ||
//       n.geometry.type === "MultiLineString"
//   );

//   const geoJson: FeatureCollection<MultiPolygon | MultiLineString> = {
//     type: "FeatureCollection",
//     features: featuresMinusLineStringsAndPoints as Feature<
//       MultiPolygon | MultiLineString,
//       GeoJsonProperties
//     >[],
//   };

//   return geoJson;
// };

const filterIntersectingPolygons = (
  features: Feature<Polygon>[],
  line: Position[]
) => {
  return features
    .filter(
      (groundType) =>
        groundType.geometry.type === "Polygon" &&
        booleanIntersects(
          lineString(line),
          polygon(groundType.geometry.coordinates)
        )
    )
    .map((m) =>
      polygon(m.geometry.coordinates, {
        ...(m.properties as Properties),
      })
    );
};

const filterIntersectingLineStrings = (
  features: Feature<LineString>[],
  line: Position[]
) => {
  return features
    .filter(
      (groundType) =>
        groundType.geometry.type === "LineString" &&
        lineIntersect(
          lineString(line),
          lineString(groundType.geometry.coordinates)
        ).features.length
    )
    .map((m) =>
      lineString(m.geometry.coordinates, {
        ...(m.properties as Properties),
      })
    );
};

export const getLineString = (
  line: Position[],
  topographies: Feature<MultiPolygon | MultiLineString>[]
) => {
  const reversedLine: Position[] = line.map((l) => [l[1], l[0]]);
  const flippedGroundTypes = flip(featureCollection(topographies));
  const flattenedGroundTypes = flatten(flippedGroundTypes);
  const polyFeatures: Feature<Polygon | LineString>[] =
    filterIntersectingPolygons(flattenedGroundTypes.features, reversedLine);

  const lineFeatures: Feature<Polygon | LineString>[] =
    filterIntersectingLineStrings(flattenedGroundTypes.features, reversedLine);

  return flip(featureCollection([...polyFeatures, ...lineFeatures]));
};

export const getPolygon = (
  poly: Position[][],
  topographies: Feature<MultiPolygon>[]
) => {
  const reversedPoly: Position[][] = poly.map((p) =>
    p.map((l) => [l[1], l[0]])
  );

  const flippedGroundTypes = flip(featureCollection(topographies));
  const flattenedGroundTypes = flatten(flippedGroundTypes);

  const features: Feature<Polygon>[] = flattenedGroundTypes.features
    .filter((groundType) =>
      booleanIntersects(
        polygon(reversedPoly),
        polygon(groundType.geometry.coordinates)
      )
    )
    .map((m) =>
      polygon(m.geometry.coordinates, {
        ...(m.properties as Properties),
      })
    );
  return flip(featureCollection(features));
};

export const getPoint = (pt: LatLng, topographies: Feature<MultiPolygon>[]) => {
  const flippedGroundTypes = flip(featureCollection(topographies));
  const flattenedGroundTypes = flatten(flippedGroundTypes);
  const feature: Feature<Polygon> | undefined =
    flattenedGroundTypes.features.find((groundType) =>
      booleanPointInPolygon(
        point([pt.lng, pt.lat]),
        polygon(groundType.geometry.coordinates)
      )
    );
  if (!feature) return;
  return flip(
    featureCollection([
      polygon(feature.geometry.coordinates, {
        ...(feature.properties as Properties),
      }),
    ])
  );
};
