import flip from "@turf/flip";
import L from "leaflet";
import { LatLng } from "leaflet";

export const coordsToLatLngs: any = (
  coords: LatLng[],
  levelsDeep: number,
  _coordsToLatLng: Function
) => {
  var latlngs = [];

  for (var i = 0, len = coords.length, latlng; i < len; i++) {
    latlng = levelsDeep
      ? coordsToLatLngs(coords[i], levelsDeep - 1, _coordsToLatLng)
      : (_coordsToLatLng || coordsToLatLng)(coords[i]);

    latlngs.push(latlng);
  }

  return latlngs;
};

// Rubbish code that needs looking at!! AGW
export const coordsToLatLng = (coords: L.LatLng[]) => {
  return { lat: coords[1], lng: coords[0] };
};

const getCoords = (feature: any, nestingLevel: number) => {
  //console.log("getCoords({type})", feature.geometry.type);
  // console.log(nestingLevel)
  // console.log(feature)
  return coordsToLatLngs(
    feature.geometry.coordinates,
    nestingLevel,
    coordsToLatLng
  );
};

const getCoord = (feature: any) => {
  // console.log("getCoord");
  // console.log(feature)
  return coordsToLatLng(feature.geometry.coordinates);
};

export const reverseCoords = (featureCollection: any) => {
  featureCollection.features.forEach((feature: any) => {
    //console.log("Reversed...");
    //console.log(feature.geometry.coordinates);

    // AGW put this here to cater for Points
    if (feature.geometry.type === "Point") {
      const flipped = flip(feature);
      feature.geometry.coordinates = flipped.geometry.coordinates;
    } else if((feature.id.startsWith("Transformers") || feature.id.startsWith("Pillars")) && feature.geometry.type === "MultiPolygon") {
      const coords = getCoords(feature, 0);
      feature.geometry.coordinates = coords;
    }
    else {
      const coords =
        feature.geometry.type === "LineString"
          ? getCoords(feature, 0)
          : feature.geometry.type === "Point"
            ? getCoords(feature, 1)
            : feature.geometry.type === "MultiLineString"
              ? getCoords(feature, 1)
              : feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon"
                ? getCoords(feature, 2)
                : getCoord(feature);
      feature.geometry.coordinates = coords;
    }
    //console.log(coords);
    //console.log("done")
  });

  return featureCollection;
};
