import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import {
  setActiveTool,
  selectNetworkCreator,
  removeFromNetwork,
  clearNetwork,
} from "../networkCreator/networkCreatorSlice";
import styles from "./StepToolBtn.module.css";
import { capitaliseString } from "../../utils/capitaliseString";

interface IStepToolBtn {
  item: any;
  cssClasses?: string;
  tabIndex?: number;
  disabled?: boolean;
  title?: string;
}

const StepToolBtn = ({ item, cssClasses = "", tabIndex, disabled = false, title = "" }: IStepToolBtn) => {
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { activeTool, network } = networkCreator;
  const dispatch = useAppDispatch();

  const onClick = (selectedTool: string) => {
    if (hasBeenAdded()) {
      if (item.name === "site") {
        dispatch(clearNetwork());
      }
      if (item.name === "meter") {
        dispatch(removeFromNetwork({ property: "name", value: "meter" }));
      }
      if (item.name === "cable") {
        dispatch(removeFromNetwork({ property: "type", value: "cable" }));
      }
    } else {
      _setActiveTool(selectedTool);
    }
  };

  const _setActiveTool = (selectedTool: string) => {
    let _activeTool: string | undefined = selectedTool;
    if (activeTool && selectedTool === activeTool) {
      _activeTool = undefined;
    }
    dispatch(setActiveTool({ activeTool: _activeTool }));
  };

  const hasBeenAdded = () => {
    return item.name === "site"
      ? network.find((i) => i.type === "site")
      : item.name === "meter"
      ? network.find((a) => a.name === "meter")
      : item.name === "cable"
      ? network.find((a) => a.type === "cable" && a.isConnected)
      : false;
  };

  const _hasBeenAdded = hasBeenAdded();
  const isActive = activeTool === item.name;
  const ariaLabel = `draw${capitaliseString(item.type || item.name)}${item.id}`;

  return (
    <div className="position-relative">
      <Button
        active={isActive}
        variant="dark"
        aria-label={ariaLabel}
        className={_hasBeenAdded ? styles.remove : styles.add}
        onClick={() => onClick(item.type || item.name)}
        tabIndex={tabIndex}
        disabled={disabled}
        title={title}
      >
        <i className={`icon-${item.type || item.name}`}></i>
        <Badge
          pill
          variant={_hasBeenAdded ? "danger" : "success"}
          className={styles.iconBadge}
        >
          <i
            className={`icon-${_hasBeenAdded ? "cross-solid" : "plus-solid"}`}
          ></i>
        </Badge>
      </Button>
    </div>
  );
};

export default StepToolBtn;
