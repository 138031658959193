import Alert from "react-bootstrap/Alert";
import { useAppSelector } from "../../app/hooks";
import { selectNetworkCreator } from "../../features/networkCreator/networkCreatorSlice";
import useNetwork from "../../hooks/useNetwork";
import { useState, useCallback, useEffect } from 'react'
import styles from './image.module.css'

import {
  siteMessages,
  meterMessages,
  cableMessages,
  submitMessages,
} from "./userGuidanceFns";
import userGuidanceMessages from "./userGuidanceMessages.json";

const UserGuidance = () => {

  const { isNetworkConnected } = useNetwork();
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { network, activeTool, vertices, firstConnectedAsset } = networkCreator;

  const networkHasSite = network.some((n) => n.type === "site");
  const networkHasMeter = network.some((n) => n.name === "meter");
  const networkHasCables = network.some((n) => n.type === "cable");

  const isFirstConnectedAssetCable =
    firstConnectedAsset &&
      ["cable", "existingcable"].includes(firstConnectedAsset.type)
      ? true
      : false;

  const _activeTool = activeTool || "";

  let header = "";
  let instruction = "";
  let image = "";

  const getUserGuidanceMessages = () => {
    switch (true) {
      case !networkHasSite:
        header = userGuidanceMessages["1"];
        const site = siteMessages(_activeTool, vertices);
        instruction = site.instruction;
        image = site.image;
        break;
      case !networkHasMeter:
        header = userGuidanceMessages["2"];
        const meter = meterMessages(_activeTool);
        instruction = meter.instruction;
        image = meter.image;
        break;
      case !isNetworkConnected:
        header = userGuidanceMessages["3"];
        const cable = cableMessages(
          _activeTool,
          vertices,
          networkHasCables,
          isFirstConnectedAssetCable
        );
        instruction = cable.instruction;
        image = cable.image;
        break;
      default:
        header = userGuidanceMessages["4"];
        const submit = submitMessages();
        instruction = submit.instruction;
        image = submit.image;
    }
  };

  getUserGuidanceMessages();

  const [click, setClick] = useState(false)

  const setFlag = () => {
    setClick(true)
  };

  const unsetFlag = () => {
    setClick(false)
  };

  return (
    <div>
      <Alert
        variant="light"
        className="text-right m-0"
        style={{ maxWidth: "15rem" }}
      >
        <h6 style={{ fontWeight: 700 }}>{header}</h6>
        <p>{instruction}</p>

        {click ? (
          <div onClick={unsetFlag} className={`${styles.lightbox} ${styles.show} relative`}>
            <img src={image} className={`img-fluid ${styles.show_image}`} alt={image} />
          </div>
        ) : (
          <img src={image} className={`img-fluid ${styles.hide_image}`} alt={image} onClick={setFlag} />
        )}
      </Alert>
    </div>
  );
};

export default UserGuidance;
