import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { ExistingNetworkAsset } from "../../models/models";
import { Feature, MultiLineString, MultiPolygon } from "geojson";

export interface ExistingNetworkState {
  conductingSections: ExistingNetworkAsset[];
  overheadPoles: ExistingNetworkAsset[];
  transformers: ExistingNetworkAsset[];
  pillars: ExistingNetworkAsset[];
  topographies: Feature<MultiPolygon | MultiLineString>[];
  selectedCable: any;
  selectedCableWeight: any;
}

const initialState: ExistingNetworkState = {
  conductingSections: [],
  overheadPoles: [],
  transformers: [],
  pillars: [],
  topographies: [],
  selectedCable: undefined,
  selectedCableWeight: undefined
};

export const existingNetworkSlice = createSlice({
  name: "existingNetwork",
  initialState,
  reducers: {
    updateConductingSections: (state, action: PayloadAction<any[]>) => {
      state.conductingSections = [...action.payload];
    },
    updateOverheadPoles: (state, action: PayloadAction<any[]>) => {
      state.overheadPoles = [...action.payload];
    },
    updateTransformers: (state, action: PayloadAction<any[]>) => {
      state.transformers = [...action.payload];
    },
    updatePillars: (state, action: PayloadAction<any[]>) => {
      state.pillars = [...action.payload];
    },
    updateGroundTypes: (state, action: PayloadAction<Feature<MultiPolygon | MultiLineString>[] | undefined>) => {
      if (!action.payload) return;
      //state.topographies = [...state.topographies, ...action.payload]; // don't append!
      state.topographies = [...action.payload];
      //console.log("state.topographies=", state.topographies);
    },
    clearExistingNetwork: (state) => {
      state.conductingSections = [];
      state.overheadPoles = [];
      state.transformers = [];
      state.pillars = [];
      state.topographies = [];
    },
    setSelectedCable: (state, action: PayloadAction<any>) => {
      state.selectedCable = action.payload;
    },
    setSelectedCableWeight: (state, action: PayloadAction<any>) => {
      state.selectedCableWeight = action.payload;
    },
  },
});

export const {
  updateConductingSections,
  updateOverheadPoles,
  updateTransformers,
  updatePillars,
  updateGroundTypes,
  clearExistingNetwork,
  setSelectedCable,
  setSelectedCableWeight,
} = existingNetworkSlice.actions;

export const selectExistingNetwork = (state: RootState) =>
  state.existingNetwork;

export default existingNetworkSlice.reducer;
